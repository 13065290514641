@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Poppins:wght@400;600&family=Syncopate:wght@700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

@font-face {
  font-family: font-1;
  src: url("../../public/fonts/Glitch\ inside.otf") format("opentype");
}

.background-container-landing {
  /* position: absolute; */
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-img-landing {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.center-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.title-landing {
  font-family: "Orbitron", sans-serif;
  font-size: 50px;
  color: #fff;
  margin-bottom: 60px;
}

.subtitle-landing {
  font-family: "Orbitron", sans-serif;
  font-size: 24px;
  color: #fff;
  /* margin-top: 50px; */
  margin-bottom: 20px;
  text-align: center;
  /* padding-left: 90px; */
}

.sub-title-landing {
  font-family: "font-1";
  font-size: 70px;
  /*to be changed*/
  color: #fff;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 60px;
  /* padding-left: 90px; */
}

.button-container-landing {
  /* margin-top: 20px; */
  display: flex;
  justify-content: center;
  /* padding-bottom: 20px; */
  /* margin-bottom: 30px; */
}

.button-landing-nssc {
  padding: 10px 20px;
  margin: 60px 10px 10px 10px;

  background-color: #3498db;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  /* margin-bottom: 200px; */
}

.button-landing-nssc {
  align-items: center;
  background-image: linear-gradient(144deg, #af40ff, #5b42f3 50%, #00ddeb);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -5px;
  box-sizing: border-box;
  color: #ffffff;
  display: flex;
  font-family: Phantomsans, sans-serif;
  font-size: 18px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 3px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;
  outline: 0;
}

.button-landing-nssc span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-landing-nssc:hover span {
  background: none;
}

.button-landing-nssc:active {
  transform: scale(0.9);
}

.background-img-title {
  width: 400px;
  display: none;
}

.astronaut-container {
  position: fixed;
  top: calc(50% - 130px);
  /* Adjust as needed */
  right: 20px;
  /* Adjust as needed */
  animation: astronautHover 3s ease-in-out infinite;
}

.astronaut-img-landing {
  /* margin-bottom: 320px; */
  width: 400px;
  /* Adjust as needed */
  height: auto;
}

.social-media-icons {
  display: flex;
  position: absolute;
  /* Use absolute positioning */
  bottom: 10px;
  /* Distance from the bottom */
  left: 10px;
  /* Distance from the left */
  /* margin-inline: 10px; */
}

/* ... existing CSS ... */

.logo-container {
  position: absolute;
  top: -25px;
  left: -25px;
  z-index: 1000;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.logo-img {
  width: 200px;
  /* Adjust the width as needed */
  height: auto;
}

/* ... rest of your existing CSS ... */

.logo-anchor-tag {
  text-align: center;
  margin-top: 5%;
  margin-bottom: 2rem;
  color: #eee;
  margin-inline: 10px;
}

.social-media-icons-nssc {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 1rem;
  list-style-type: none;
  font-size: 2rem;
  text-align: center;
}

.social-media-icons-nssc li {
  padding: 0.5rem;
}

.logo-anchor-tag .fab {
  font-size: 2rem;
}

/* transition for social icon hover animation */
.fa-facebook,
.fa-codepen,
.fa-youtube,
.fa-instagram,
.fa-github,
.fa-pinterest {
  transition: 0.4s;
  cursor: pointer;
}

/* social media icons hover color */
.fa-facebook:hover,
.fa-facebook:focus {
  color: #1877f2;
  transform: scale(1.1);
}

.fa-twitter:hover,
.fa-twitter:focus {
  color: #1da1f2;
  transform: scale(1.1);
}

.fa-youtube:hover,
.fa-youtube:focus {
  color: #df2426;
  transform: scale(1.1);
}

.fa-instagram:hover,
.fa-instagram:focus {
  color: #bd22a2;
  transform: scale(1.1);
}

.fa-github:hover,
.fa-github:focus {
  color: #000;
  transform: scale(1.1);
}

.fa-github:hover,
.fa-github:focus {
  color: #000;
  transform: scale(1.1);
}

.fa-codepen:hover,
.fa-codepen:focus {
  color: #000;
  transform: scale(1.1);
}

.fa-pinterest:hover,
.fa-pinterest:focus {
  color: #b3001b;
}

.wrapper-landing {
  /* height: 100vh; */
  /*This part is important for centering*/
  display: grid;
  place-items: center;
  color: #ffffff;

  font-size: 20px;
  color: #fff;
  /* margin-top: 50px; */
  margin-bottom: 20px;
  text-align: center;
  /* padding-left: 90px; */
}

.typing-demo {
  width: 33rem;
  animation: typing 2s steps(20), blink 1s step-end infinite alternate;
  white-space: nowrap;
  overflow: hidden;
  border-right: 3px solid;
  font-size: 2rem;
  font-family: "Orbitron", sans-serif;
  padding-bottom: 20px;
  position: fixed;
}

@keyframes typing {
  from {
    width: 0;
  }
}

@keyframes blink {
  50% {
    border-color: transparent;
  }
}

@keyframes astronautHover {

  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-15px);
  }
}

.right-logo {
  position: absolute;
  right: -5px;
  display: flex;
  z-index: 1000;
  gap: 1rem;
}

.isro-logo,
.iit-logo {
  width: 5.5rem;
  height: auto;
}

@media only screen and (max-width: 1300px) and (min-width: 847px) {
  .title-landing {
    font-family: "Orbitron", sans-serif;
    font-size: 40px;
    color: #fff;
    margin-bottom: 40px;
  }

  .wrapper-landing {
    display: grid;
    place-items: center;
    color: #ffffff;
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }

  .sub-title-landing {
    font-family: "font-1";
    font-size: 50px;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 70px;
    /* padding-left: 90px; */
  }

  .astronaut-img-landing {
    display: none;
  }

  .background-img-title {
    scale: 0.9;
  }
}

@media only screen and (max-width: 847px) and (min-width: 551px) {
  .title-landing {
    font-family: "Orbitron", sans-serif;
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
  }

  .wrapper-landing {
    display: grid;
    place-items: center;
    color: #ffffff;

    font-size: 16px;
    color: #fff;
    /* margin-top: 50px; */
    margin-bottom: 20px;
    text-align: center;
    /* padding-left: 90px; */
    scale: 0.8;
  }

  .sub-title-landing {
    font-family: "font-1";
    font-size: 50px;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    /* padding-left: 90px; */
  }

  .astronaut-img-landing {
    display: none;
  }

  .background-img-title {
    scale: 0.8;
  }

  .logo-img {
    width: 125px;
    /* Adjust the width as needed */
    height: auto;
  }

  .isro-logo,
  .iit-logo {
    width: 3.8rem;
    height: auto;
  }

  .right-logo {
    gap: 0.5rem;
  }
}

@media only screen and (max-width: 551px) and (min-width: 395px) {
  .title-landing {
    font-family: "Orbitron", sans-serif;
    font-size: 30px;
    color: #fff;
    margin-bottom: 20px;
    /* scale: 0.7; */
  }

  .wrapper-landing {
    font-family: "Orbitron", sans-serif;
    font-size: 20px;
    color: #fff;
    /* margin-top: 50px; */
    margin-bottom: 10px;
    text-align: center;
    scale: 0.6;

    /* padding-left: 90px; */
  }

  .sub-title-landing {
    font-family: "font-1";
    font-size: 30px;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    /* padding-left: 90px; */
  }

  .astronaut-img-landing {
    display: none;
  }

  .background-img-title {
    scale: 0.75;
  }

  .button-container-landing {
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    scale: 0.8;
    /* padding-bottom: 20px; */
    /* margin-bottom: 30px; */
  }

  /* .logo-container {
    left: calc(50vw - 100px);
    position: absolute;
    top: -5%;
    z-index: 1000;
  } */
  .isro-logo,
  .iit-logo {
    width: 3.8rem;
    height: auto;
  }

  .logo-img {
    width: 125px;
    /* Adjust the width as needed */
    height: auto;
  }
}

@media only screen and (max-width: 395px) and (min-width: 200px) {
  .title-landing {
    font-family: "Orbitron", sans-serif;
    font-size: 22px;
    color: #fff;
    margin-bottom: 20px;
    /* scale: 0.7; */
  }

  .wrapper-landing {
    font-family: "Orbitron", sans-serif;
    font-size: 20px;
    color: #fff;
    /* margin-top: 50px; */
    margin-bottom: 10px;
    text-align: center;
    scale: 0.45;

    /* padding-left: 90px; */
  }

  .sub-title-landing {
    font-family: "font-1";
    font-size: 30px;
    color: #fff;
    text-align: center;
    padding-top: 20px;
    padding-bottom: 30px;
    /* padding-left: 90px; */
  }

  .astronaut-img-landing {
    display: none;
  }

  .background-img-title {
    scale: 0.75;
  }

  .button-container-landing {
    /* margin-top: 20px; */
    display: flex;
    justify-content: center;
    scale: 0.8;
    /* padding-bottom: 20px; */
    /* margin-bottom: 30px; */
  }

  .logo-img {
    width: 125px;
    /* Adjust the width as needed */
    height: auto;
  }

  .isro-logo,
  .iit-logo {
    width: 3.8rem;
    height: auto;
  }
}