/* .merchandise-page{
  background-color: gray;
} */

.merchandise-page-main-content{
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: rgba(0, 0, 0, 0.5); */
  height: 1000px;
  margin: 0 auto;
}

.merchandise-page-title{
  font-family: 'Raleway';
  font-size: 60px;
  margin: 40px 0;
  color: white;
}

.merchandise-items-wrap{
  /* background-color: blue; */
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
}

@media (max-width: 1300px){
  .merchandise-page-main-content{
    height: 700px;
  }
} 

@media (max-width: 1050px){
    .merchandise-items-wrap{
      flex-direction: column;
    }  

    .merchandise-page-main-content{
      height: 1600px;
    }

}

@media (max-width: 550px) {
  .merchandise-page-title{
    font-size: 40px;
    margin: 0;
    margin-top: 20px;
  }
}
