.timeline {
    min-height: 100vh;
    display: grid;
    align-content: center;
    gap: 2rem;
    padding: 2rem;
    font-family: "Poppins", sans-serif;
    margin: 3rem 0;
    background-color: rgba(0, 0, 0, 0.436);
    border-radius: 20px;
    overflow-x: hidden;
    font-size: 20px;
    letter-spacing: 0.8px;
    font-family: monospace;
    font-weight: 500;
    margin-inline: 20vw;
}

@media only screen and (max-width: 419px) and (min-width: 320px) {

    .timeline {
        min-height: 100vh;
        display: grid;
        align-content: center;
        gap: 2rem;
        padding: 2rem;
        font-family: "Poppins", sans-serif;
        margin: 3rem 0;
        background-color: rgba(0, 0, 0, 0.436);
        border-radius: 20px;
        overflow-x: hidden;
        font-size: 20px;
        letter-spacing: 0.8px;
        font-family: monospace;
        font-weight: 500;
        /* margin-inline: 20vw; */
    }
}

@media only screen and (max-width: 362px) and (min-width: 320px) {

    .timeline {
        min-height: 100vh;
        display: grid;
        align-content: center;
        gap: 2rem;
        padding: 0.5rem;
        font-family: "Poppins", sans-serif;
        margin: 3rem 0;
        background-color: rgba(0, 0, 0, 0.436);
        border-radius: 20px;
        overflow-x: hidden;
        font-size: 20px;
        letter-spacing: 0.8px;
        font-family: monospace;
        font-weight: 500;
        /* margin-inline: 20vw; */
    }

    .timeline ul li {
        grid-column: 2;
        --inlineP: 1.5rem;
        margin-inline: var(--inlineP);
        grid-row: span 2;
        display: grid;
        grid-template-rows: min-content min-content min-content;
        background-color: white;
        box-shadow: 0.5rem 0.5rem 5rem #21212122;
        border-radius: 1rem;
        scale: 0.9;
    }
}


@media only screen and (max-width: 1140px) and (min-width: 419px) {
    .timeline {
        min-height: 100vh;
        display: grid;
        align-content: center;
        gap: 2rem;
        padding: 2rem;
        font-family: "Poppins", sans-serif;
        margin: 3rem 0;
        background-color: rgba(0, 0, 0, 0.436);
        border-radius: 20px;
        overflow-x: hidden;
        font-size: 20px;
        letter-spacing: 0.8px;
        font-family: monospace;
        font-weight: 500;
        margin-inline: 10vw;
    }


}

.timeline h1 {
    text-align: center;
}

.timeline ul {
    --col-gap: 2rem;
    --row-gap: 15rem;
    --line-w: 0.25rem;
    display: grid;
    grid-template-columns: var(--line-w) 1fr;
    grid-auto-columns: max-content;
    column-gap: var(--col-gap);
    list-style: none;
    width: min(60rem, 90%);
    margin-inline: auto;
}

/* line */
.timeline ul::before {
    content: "";
    grid-column: 1;
    grid-row: 1 / span 20;
    background: rgb(255, 255, 255);
    transform: translateY(3px);
    /* height: 84%; */
    border-radius: calc(var(--line-w) / 2);
}

/* columns*/

/* row gaps */
.timeline ul li {
    margin-bottom: 8em;

}

/* card */
.timeline ul li {
    grid-column: 2;
    --inlineP: 1.5rem;
    margin-inline: var(--inlineP);
    grid-row: span 2;
    display: grid;
    grid-template-rows: min-content min-content min-content;
    background-color: white;
    box-shadow: 0.5rem 0.5rem 5rem #21212122;
    border-radius: 1rem;
}



/* date */
.timeline ul li .date {
    /* width: 132%; */
    --dateH: 3rem;
    height: var(--dateH);
    margin-inline: calc(var(--inlineP) * -1);

    text-align: center;
    background-color: black;

    color: white;
    font-size: 1.25rem;
    font-weight: 700;

    display: grid;
    place-content: center;
    position: relative;

    border-radius: calc(var(--dateH) / 2) 0 0 calc(var(--dateH) / 2);
}

/* date flap */
.timeline ul li .date::before {
    content: "";
    width: var(--inlineP);
    aspect-ratio: 1;
    background: #279EFF;
    background-image: linear-gradient(rgba(0, 0, 0, 0.2) 100%, transparent);
    position: absolute;
    top: 100%;

    clip-path: polygon(0 0, 100% 0, 0 100%);
    right: 0;
}

.title-descr-container {
    transition: all 0.4s ease;
}

.title-descr-container:hover {
    scale: 1.05;
}

/* circle */
.timeline ul li .date::after {
    content: "";
    position: absolute;
    width: 2rem;
    aspect-ratio: 1;
    background: rgb(0, 0, 0);
    border: 0.3rem solid white;
    border-radius: 50%;
    top: 50%;
    transform: translate(50%, -50%);
    right: calc(100% + var(--col-gap) + var(--line-w) / 2);
}

/* title descr */
.timeline ul li .title,
.timeline ul li .descr {
    background: var(--bgColor);
    position: relative;
    padding-inline: 1.5rem;

}



.timeline ul li .title {
    overflow: hidden;
    padding-block-start: 1.5rem;
    padding-block-end: 1rem;
    font-weight: 600;
}

.timeline ul li .descr {
    padding-block-end: 1.5rem;
    font-weight: 300;
    cursor: pointer;
    display: flex;
}

.location-schedule {
    margin-left: 10px;
    text-decoration: None;
}

.location-schedule a {
    color: rgb(226, 143, 7);
}


@media (min-width: 800px) {
    .timeline ul {
        grid-template-columns: 1fr var(--line-w) 1fr;
    }

    .timeline ul::before {
        grid-column: 2;
    }

    .timeline ul li:nth-child(odd) {
        grid-column: 1;
    }

    .timeline ul li:nth-child(even) {
        grid-column: 3;
    }

    /* start second card */
    .timeline ul li:nth-child(2) {
        grid-row: 2/4;
    }

    .timeline ul li:nth-child(odd) .date::before {
        clip-path: polygon(0 0, 100% 0, 100% 100%);
        left: 0;
    }

    .timeline ul li:nth-child(odd) .date::after {
        transform: translate(-50%, -50%);
        left: calc(100% + var(--col-gap) + var(--line-w) / 2);
    }

    .timeline ul li:nth-child(odd) .date {
        border-radius: 0 calc(var(--dateH) / 2) calc(var(--dateH) / 2) 0;
    }
}

@media (max-width:800px) {

    /* body .timeline {
        transform: scale(0.8) translateY(-24rem);
    } */
    .timeline ul li {
        margin-bottom: 3em;
        /* row-gap: 2px; */
    }
}