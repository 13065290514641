@import url("https://fonts.googleapis.com/css?family=Bruno+Ace");
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400&display=swap");

.team-box {
  /* background-image: url("./img/galaxyback.jpg"); */
  /* background-color: #080808; */
  padding-left: 3px;
  padding-right: 3px;
  opacity: 1;
  margin-bottom: 0px;
  padding-bottom: 35px;
}

.team-innerbox {
  background-color: rgba(62, 53, 79, 0.5);
  margin-left: 100px;
  margin-right: 100px;
  /* margin-bottom: 25px; */
  padding-left: 15px;
  opacity: 1;
  border-radius: 15px;
}

.team-page-header,
.team-page-content {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.team-page-header b {
  display: block;
  font-size: 3.8rem;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  text-align: center;
}

.team-page-cat-select {
  width: 80%;
  display: flex;
  justify-content: space-evenly;
  font-size: 1.5rem;
  margin-bottom: 1rem;
  transition-duration: 5s;
}

/* CSS for glow and hover button */
.team-page-cat {
  padding: 0.5rem 1rem;
  border-radius: 12px;
  border-style: inset;
  border-width: 1.75px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  background: #111;
  cursor: pointer;
  position: relative;
  z-index: 0;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
  font-size: 0.85em;
}
.team-page-cat:before {
  content: "";
  background: linear-gradient(
    45deg,
    rgb(16, 165, 140),
    rgb(5, 213, 240),
    rgb(16, 115, 165),
    rgb(7, 235, 197),
    rgb(5, 44, 240),
    rgb(16, 165, 100),
    rgb(9, 225, 59)
  );
  position: absolute;
  top: -2px;
  left: -2px;
  background-size: 400%;
  z-index: -1;
  filter: blur(5px);
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  animation: glowing-button 10s alternate-reverse infinite;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  border-radius: 10px;
}

.team-page-cat:active {
  color: #000;
}

.team-page-cat:active:after {
  background: transparent;
}

.team-page-cat:hover::before,
.team-page-cat-isactive::before {
  opacity: 1;
}

.team-page-cat:after {
  z-index: -1;
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #111;
  left: 0;
  top: 0;
  border-radius: 10px;
}

@keyframes glowing-button {
  0% {
    background-position: 0 0;
  }
  50% {
    background-position: 200% 0;
  }
  100% {
    background-position: 400% 0;
  }
}

/* CSS for member cards and title */
.team-page-content-title {
  font-size: 2.3rem;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
  font-weight: 500;
  margin-top: 0.5rem;
  margin-bottom: 1.75rem;
}
.team-page-content-title::after {
  content: "";
  width: 80%;
  margin: auto auto;
  height: 0.1px;
  border-style: dashed;
  border-color: white;
  border-width: 0.6px;
  display: block;
}
.team-page-content-members {
  width: 88%;
  margin: 0 auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  row-gap: 2vw;
  margin-bottom: 2.5rem;
  transition-delay: 1s;
}

/* Responsive */
@media screen and (max-width: 650px) {
  .team-page-cat-select {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .team-page-content-members {
    row-gap: 1rem;
  }
  .team-page-header b {
    font-size: 3rem;
  }
}

@media screen and (max-width: 330px) {
  .team-page-header b {
    font-size: 2.5rem;
  }
  .team-page-cat {
    font-size: 0.7em;
  }
  .team-page-content-title {
    font-size: 1.8rem;
  }
}
