@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Poppins:wght@400;600&display=swap");

.glow-on-hover {
    background: #a2a0aa;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #e3d7d7;
    background: #c9c9d9;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.sponsors {
    /* margin-top: 10vh; */
    margin-left: 10vw;
    margin-right: 10vw;
    border-radius: 40px;
    overflow-x: hidden;
    background-color: #111111af;
}

.glow-on-hover:before {
    content: "";
    background: linear-gradient(90deg,
            #ff7300,
            #9d00ff,
            #5142c4,
            #002bff,
            #350c61,
            #350c61,
            #002bff,
            #5142c4,
            #9d00ff,
            #ff7300);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    border-radius: 10px;
}

.button-sponsors button {
    opacity: 1;
    color: black;
}

.button-sponsors button.active {
    opacity: 1;
}

.glow-on-hover:active {
    color: #ffffff;
}

.glow-on-hover:active:after {
    background: transparent;
}

.glow-on-hover:hover:before,
.glow-on-hover.active:before {
    opacity: 1;
}

.glow-on-hover:after,
.glow-on-hover.active:after {
    z-index: -1;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ffffff;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}

.heading-sponsors-text {
    font-family: "Orbitron", sans-serif;
}

.div-sponsors-button {
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100vw;
}

/* .button-sponsors {
    background-color: white;
} */

.hero-section-sponsors-nssc {
    margin: 6rem 0;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-section-sponsors-nssc .section-sponsors {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 90%;
    margin: 2.6rem auto;
}

.hero-section-sponsors-nssc .section-sponsors h1 {
    font-size: 1.8rem;
    color: white;
    font-family: "Orbitron", sans-serif;
}

.hero-section-sponsors-nssc .section-sponsors .cards {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    margin: 2rem 0;
    flex-wrap: wrap;
    width: 100%;
}

.hero-section-sponsors-nssc .section-sponsors .cards .card {
    background: transparent;
    /* box-shadow: 0px 0px 20px rgb(0 0 0 / 10%); */
    /* border-bottom: 3px solid #066b23; */
    border-radius: 10px;
    /* transition: 0.3s ease; */
    /* padding: 1rem; */
    width: 300px;
    height: 240px;
    /* transition: all 0.1s linear; */
    cursor: pointer;
}

/* .hero-section-sponsors-nssc .section-sponsors .cards .card:hover {
    border-width: 5px;
} */

.hero-section-sponsors-nssc .section-sponsors .cards .card img {
    height: 100%;
    /* max-width: 90%; */
    /* transition: all 0.1s linear; */
}

.sponsors-heading {
    /* height: 2em; */
    margin-top: 1em;
    text-align: center;
    /* margin-left: 25%;
    margin-right: 25%; */

    margin-bottom: 2em;
    font-size: 1.4em;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    text-decoration: underline;
}

@media (max-width: 800px) {
    .media {
        margin: 10vh 0;
    }
}

@media only screen and (max-width: 898px) and (min-width: 546px) {
    .glow-on-hover {
        background: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 120px;
        height: 50px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }
}

@media only screen and (max-width: 546px) and (min-width: 200px) {
    .glow-on-hover {
        background: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 80px;
        height: 50px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }

    .div-sponsors-button {
        margin-top: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 1em;
        width: 100vw;
    }

    .hero-section-sponsors-nssc .section-sponsors .cards .card {
        background: transparent;
        /* box-shadow: 0px 0px 20px rgb(0 0 0 / 10%); */
        /* border-bottom: 3px solid #066b23; */
        border-radius: 10px;
        /* transition: 0.3s ease; */
        /* padding: 1rem; */
        width: 300px;
        height: 240px;
        /* transition: all 0.1s linear; */
        cursor: pointer;
        scale: 0.8;
    }

    .sponsors {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
        border-radius: 20px;
    }

    .heading-sponsors-text {
        font-size: 36px;
    }
}