@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Poppins:wght@400;600&display=swap');

.glow-on-hover-schedule {
    background: #a2a0aa;
    display: flex;
    /* flex-direction: row; */
    justify-content: center;
    align-items: center;
    width: 220px;
    height: 50px;
    border: none;
    outline: none;
    color: #e3d7d7;
    background: #c9c9d9;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.schedule {
    margin-top: 50px;
    margin-left: 3vw;
    margin-right: 3vw;
    border-radius: 20px;
    overflow-x: hidden;
    /* background-color: #111111af; */
}

.glow-on-hover-schedule:before {
    content: '';
    background: linear-gradient(90deg, #ff7300, #9d00ff, #5142c4, #002bff, #350c61, #350c61, #002bff, #5142c4, #9d00ff, #ff7300);
    position: absolute;
    top: -2px;
    left: -2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.button-schedule button {
    opacity: 1;
    color: black;

}

.button-schedule button.active {
    opacity: 1;


}

.glow-on-hover-schedule:active {
    color: #ffffff;
    /* background: transparent; */
}

.glow-on-hover-schedule:active:after {
    background: transparent;
}

.glow-on-hover-schedule:hover:before,
.glow-on-hover-schedule.active:before {
    opacity: 1;
}

.glow-on-hover-schedule:after,
.glow-on-hover-schedule.active:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #ef9505;
    left: 0;
    top: 0;
    border-radius: 10px;
}

@keyframes glowing {
    0% {
        background-position: 0 0;
    }

    50% {
        background-position: 400% 0;
    }

    100% {
        background-position: 0 0;
    }
}



.div-schedule-button {
    margin-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100vw;

}

@media only screen and (max-width: 705px) and (min-width: 546px) {

    .glow-on-hover-schedule {
        background: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 150px;
        height: 50px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }

}

@media only screen and (max-width: 546px) and (min-width: 200px) {

    .glow-on-hover-schedule {
        background: #000;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 50px;
        border: none;
        outline: none;
        color: #fff;
        background: #111;
        cursor: pointer;
        position: relative;
        z-index: 0;
        border-radius: 10px;
    }

    .div-schedule-button {
        margin-top: 3px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;
        gap: 1em;
        width: 100vw;

    }

}