.login {
  height: calc(100svh - 83.5px);
  width: 100%;
  overflow: hidden;
  margin: 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
  /* background: radial-gradient(
    167.42% 102.36% at 63.8% 33.21%,
    #242535 0%,
    #242535 86.2%,
    #242535 100%
  ); */
  position: relative;
  isolation: isolate;
}

.login-signup-options {
  width: 28vw;
  /* background: #19293b;
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.6); */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -47.5%);
  z-index: 3;
  font-family: "Rubik", monospace, sans-serif;
}

.login-signup-options-select {
  padding: 1rem 0;
  padding-bottom: 1em;
  text-align: center;
  display: grid;
}

.login-signup-options-select h2 {
  width: fit-content;
  max-width: 100%;
  padding: 5px 0;
  border-radius: 1em;
  background-color: #17191c;
  /* margin-left: 50%;
  transform: translateX(-50%); */
  margin: 0 auto;
  text-align: center;
  position: relative;
  isolation: isolate;
  /* display: flex;
  flex-direction: row;
  justify-content: space-between; */
}

.login-signup-options-select h2 span:not(:last-child) {
  padding: 0.2em 0.8em;
  /* width: 50%; */
  display: inline-block;
  color: white;
  text-align: center;
  mix-blend-mode: difference;
  cursor: pointer;
  user-select: none;
  -moz-user-select: none;
}

.login-signup-options-select h2 .login-signup-options-select-slider {
  position: absolute;
  top: 0;
  bottom: 0;
  /* right: 0;
  width: 60%; */
  background-color: white;
  z-index: -1;
  border-radius: 1em;
  transition-duration: 1s;
}

.login-signup-options-box {
  width: 100%;
  height: 445px;
  position: relative;
}

.login-box,
.signup-box {
  /* overflow: hidden; */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transition: transform 0.4s ease-in-out;
  padding: 2em;
  box-sizing: border-box;
  border-radius: 10px;
  font-family: sans-serif;
  animation-name: slide-open-support;
  animation-duration: 3s;
  animation-timing-function: ease;
}

.login-box {
  padding-top: 3.5em;
}

.signup-box {
  padding-top: 1em;
}

/* 
.login-box h2,
.signup-box h2 {
  margin: 0 0 30px;
  padding: 0;
  color: #fff;
  font-size: 2em;
  text-align: center;
} */

.signup-box h2,
.signup-otp h2,
.login-signup-options h1,
.signup-otp p,
.signup-box > p {
  color: white;
  width: 100%;
  text-align: center;
  margin-bottom: 1em;
  font-family: "Rubik", sans-serif;
  font-weight: normal;
}
.signup-box > p {
  font-size: 0.85em;
  color: #bebebe;
}

.signup-box h2 {
  margin-bottom: 0.2em;
}

.signup-otp p {
  margin-bottom: 2em;
}

.signup-otp h2 {
  font-size: 1.8em;
  margin-bottom: 0.5em;
}

.login-box .login-user-box,
.signup-box .signup-user-box,
.signup-otp-box {
  position: relative;
}

.signup-box .signup-user-box.signup-multi > .signup-user-box {
  position: relative;
  display: inline-block;
  width: 48%;
}

.signup-box .signup-user-box.signup-multi > .signup-user-box:first-child {
  margin-right: 4%;
}

.login-box .login-user-box input,
.signup-user-box input,
.signup-otp-box input {
  width: 100%;
  padding: 10px 1em;
  font-size: 1em;
  color: #fff;
  margin-bottom: 1.1em;
  border-radius: 2em;
  border: 1px solid #fff;
  outline: none;
  background: transparent;
  transition-duration: 0.4s;
}

.signup-user-box input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.login-box .login-user-box input {
  margin-bottom: 1.25em;
}

.login-box .login-user-box label,
.signup-box .signup-user-box label,
.signup-otp-box label {
  position: absolute;
  top: 0;
  left: 1em;
  padding: 10px 0;
  font-size: 1em;
  color: #bebebe;
  pointer-events: none;
  transition: 0.4s;
}

.signup-box .signup-user-box.signup-gender label.required::after {
  content: " (M/F)*";
}

.signup-box .signup-user-box label.required::after {
  content: "*";
}

.login-box .login-user-box input:focus ~ label,
.login-box .login-user-box input:valid ~ label,
.signup-user-box:not(.signup-dd) > input:focus ~ label,
.signup-user-box:not(.signup-dd) > input:valid ~ label,
.signup-box .signup-user-box.signup-filled label:not(.downarrow),
.signup-otp-box input:valid ~ label,
.signup-otp-box input:focus ~ label {
  top: 0;
  left: 1em;
  transform: translateY(-60%);
  color: #03e9f4;
  font-size: 0.75em;
  /* background: rgba(36, 37, 53, 1); */
  padding: 0 3px;
}

.signup-user-box:not(.signup-dd) > input:focus,
.signup-user-box:not(.signup-dd) > input:valid,
.login-box .login-user-box input:focus,
.login-box .login-user-box input:valid,
.signup-box .signup-user-box.signup-filled input,
.signup-otp-box input:valid,
.signup-otp-box input:focus {
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0;
}

.login-box form .login-form-footer,
.signup-box form .signup-form-footer,
.signup-otp-footer {
  width: 90%;
  margin: 0 5%;
}

.login-box form button,
.signup-box form button,
.signup-otp button {
  position: relative;
  padding: 0.63em 1.25em;
  color: white;
  font-size: 1em;
  text-decoration: none;
  text-transform: uppercase;
  overflow: hidden;
  transition: 0.5s;
  margin-top: 0.5em;
  letter-spacing: 4px;
  border: 1px solid white;
  border-radius: 2em;
  background: transparent;
  cursor: pointer;
  isolation: isolate;
  z-index: 3;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.login-box form button:first-child,
.signup-box form button:first-child,
.signup-otp-footer button:first-child {
  float: right;
  border-color: #03e9f4;
  color: #03e9f4;
}

.login-box form button::before,
.signup-box form button::before,
.signup-otp-footer button::before {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  left: 0;
  width: 0;
  background-color: white;
  mix-blend-mode: difference;
  transition-duration: 0.7s;
}

.login-box form button:first-child::before,
.signup-box form button:first-child::before,
.signup-otp-footer button:first-child:before {
  background-color: #03e9f4;
}

.login-box form button:hover::before,
.signup-box form button:hover::before,
.signup-otp-footer button:hover::before {
  width: 100%;
}

.login-form-footer > span {
  display: block;
  font-size: 1em;
  width: fit-content;
  color: #cfcfcf;
  padding: 1.35em 0.2em;
  cursor: pointer;
  transition-duration: 0.4s;
  text-decoration: underline;
  font-family: sans-serif;
}

.login-form-footer > span:hover {
  color: #03e9f4;
  scale: 1.01;
}

.signup-box .signup-progress {
  width: 80%;
  position: relative;
  margin: 0 10%;
  margin-bottom: 1.5em;
  isolation: isolate;
}

.signup-box .signup-progress span {
  width: 1.5em;
  height: 1.5em;
  line-height: 1.4em;
  display: inline-block;
  border-radius: 50%;
  border: 1px solid white;
  background-color: #242535;
  margin: 0 calc(100% / 6 - 1em);
  color: white;
  text-align: center;
}

.signup-box .signup-progress span:nth-child(1) {
  margin-left: 0;
}

.signup-box .signup-progress span:nth-child(4) {
  margin-right: 0;
}

.signup-box .signup-progress span.signup-step-completed {
  background-color: yellow;
  border-color: #242535;
  color: #19293b;
}

.signup-box .signup-progress .signup-line {
  content: "";
  height: 1px;
  width: 100%;
  position: absolute;
  left: 0;
  top: 50%;
  z-index: -1;
}

@keyframes astronaut-animation {
  0% {
    transform: translateY(-50%);
  }

  50% {
    transform: translateY(calc(-50% + 15px));
  }

  100% {
    transform: translateY(-50%);
  }
}

@keyframes astronaut-animation-2 {
  0% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(15px);
  }

  100% {
    transform: translateY(0);
  }
}

.login .login-astronaut-image {
  position: absolute;
  top: 37%;
  left: 10%;
  width: 16%;
  /* transform: translateY(-50%); */
  z-index: 2;
  animation-name: astronaut-animation;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease;
  transition-duration: 1s;
}

.login .login-moon-image {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 48%;
  z-index: -1;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.login .login-saturn-image {
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: -1;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.login .login-star-images img {
  position: absolute;
  z-index: -1;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -webkit-user-drag: none;
}

.login .login-star-images img:nth-child(1) {
  top: 20%;
  right: 30%;
}

.login .login-star-images img:nth-child(2) {
  top: 10%;
  left: 10%;
}

.login .login-star-images img:nth-child(3) {
  top: 15%;
  left: 30%;
}

.login .login-star-images img:nth-child(4) {
  top: 30%;
  left: 5%;
}

.login .login-star-images img:nth-child(5) {
  top: 42%;
  left: 33%;
}

.login .login-star-images img:nth-child(6) {
  top: 33%;
  right: 8%;
}

.signup-box .signup-user-box.signup-dd input {
  cursor: default;
}

.signup-box .signup-user-box.signup-dd > label.downarrow {
  left: auto;
  right: 1em;
}

.signup-box .signup-user-box.signup-dd:focus-within > label.downarrow {
  transform: rotateZ(180deg);
}

.signup-dd ul {
  background-color: #132438;
  position: absolute;
  width: 95%;
  left: 2.5%;
  max-height: 0px;
  top: calc(100% - 1.1em + 8px);
  list-style-type: none;
  border-radius: 8px;
  z-index: 5;
  overflow: hidden;
  transition-delay: 0.25s;
  transition-duration: 0.7s;
}

.signup-dd:focus-within ul {
  max-height: 200px;
  transition-delay: 0.02s;
}

.signup-dd.state ul {
  overflow: auto;
}

.signup-dd ul > li {
  padding: 0.8em 1.5em;
  padding-right: 0;
  color: white;
  cursor: default;
}

.signup-dd ul li:hover {
  background-color: #162f4c;
}

.signup-dd ul::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.signup-dd ul::-webkit-scrollbar-track {
  border-radius: 10px;
}

/* Handle */
.signup-dd ul::-webkit-scrollbar-thumb {
  background: rgb(174, 174, 174);
  border-right: 5px solid rgba(0, 0, 0, 0);
  border-top: 1px solid rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(0, 0, 0, 0);
  border-left: 5px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-clip: padding-box;
  width: 5px;
}

/* Handle on hover */
.signup-dd ul::-webkit-scrollbar-thumb:hover {
  background: gray;
  border: 3px solid rgba(0, 0, 0, 0);
  border-radius: 10px;
  background-clip: padding-box;
}

.spinner-border {
  width: 2.5em;
  height: 2.5em;
  float: right;
  margin-top: 0.5em;
  margin-right: 1.2em;
}

.spinner-inner {
  color: #fff;
  position: relative;
  border-radius: 50%;
  border: 0.3em solid #eee;
  border-top: 0.3em solid #03e9f4;
  z-index: 3;
  width: 100%;
  height: 100%;
  box-shadow: 1px rgba(0, 0, 0, 0.2);
  animation: spiner 1s infinite linear;
  float: right;
}

@keyframes spiner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1460px) {
  .login-signup-options {
    width: 30vw;
  }

  .login .login-astronaut-image {
    width: 20%;
  }

  /* .login-signup-options-select h2 {
    width: 60%;
  } */
}

@media (max-width: 1250px) {
  .login-signup-options {
    width: 35vw;
  }

  .signup-box {
    padding-top: 1em;
  }

  .login .login-astronaut-image {
    width: 25%;
  }
}

@media (max-width: 1050px) {
  .login-signup-options {
    width: 40vw;
    font-size: 0.9rem;
  }

  .login .login-astronaut-image {
    left: 5%;
  }

  .login .login-moon-image {
    width: 60%;
  }

  .login .login-saturn-image {
    scale: 0.95;
  }

  .login .login-star-images img {
    scale: 0.9;
  }
}

@media (max-width: 900px) {
  .login-signup-options {
    width: 45vw;
    font-size: 0.8rem;
  }

  .login-signup-options-box {
    height: 375px;
  }

  .login .login-astronaut-image {
    width: 30%;
  }
}

@media (max-width: 825px) {
  .login-signup-options {
    width: 50vw;
    font-size: 0.75rem;
    left: 65%;
  }

  .login-signup-options-box {
    height: 305px;
  }

  .login .login-moon-image {
    width: 70%;
  }

  .login .login-saturn-image {
    scale: 0.85;
  }

  .login .login-star-images img {
    scale: 0.8;
  }

  .login-box form button,
  .signup-box form button,
  .signup-otp-footer button {
    border: none;
  }

  .login-box form button::before,
  .signup-box form button::before,
  .signup-otp-footer button::before {
    width: 100%;
  }
}

@media (max-width: 665px) {
  .login-signup-options {
    width: 55vw;
    font-size: 0.72rem;
  }

  .login .login-astronaut-image {
    width: 33%;
  }

  .login .login-moon-image {
    width: 80%;
  }

  .login .login-saturn-image {
    scale: 0.78;
  }

  .login .login-star-images img {
    scale: 0.7;
  }
}

@media (max-width: 550px) {
  .login-signup-options {
    width: 65vw;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -48%);
  }

  .login .login-astronaut-image {
    height: 35%;
    width: auto;
    scale: 1;
    top: 0;
    transform: translateY(0);
    animation-name: astronaut-animation-2;
    filter: brightness(0.75) contrast(0.8);
  }
}

@media (max-width: 440px) {
  .login-signup-options {
    width: 80vw;
  }

  .login .login-moon-image {
    width: 90%;
  }

  .login .login-saturn-image {
    scale: 0.68;
  }

  .login .login-star-images img {
    scale: 0.6;
  }
}

@media (max-width: 370px) {
  .login-signup-options {
    width: 90vw;
  }

  .login .login-moon-image {
    width: 110%;
  }

  .login .login-saturn-image {
    scale: 0.58;
  }

  .login .login-star-images img {
    scale: 0.5;
  }
}

@media (max-width: 325px) {
  .login-signup-options {
    width: 100vw;
  }
}

@media (min-height: 1000px) and (max-width: 1050px) and (min-width: 550px) {
  .login-signup-options {
    width: 45vw;
    font-size: 1.05rem;
  }

  .login-signup-options-box {
    height: 480px;
  }

  .signup-box h2 {
    margin-bottom: 1.2em;
  }

  .signup-box .signup-user-box > input {
    margin-bottom: 1.8em;
  }
}

@media (min-height: 1000px) and (max-width: 920px) and (min-width: 550px) {
  .login-signup-options {
    width: 50vw;
    font-size: 0.95rem;
  }
}

@media (min-height: 1000px) and (max-width: 825px) and (min-width: 550px) {
  .login-signup-options {
    width: 55vw;
    left: 60%;
    font-size: 0.85rem;
  }

  .login-signup-options-box {
    height: 390px;
  }
}

@media (min-height: 1000px) and (max-width: 665px) and (min-width: 550px) {
  .login-signup-options {
    width: 65vw;
    font-size: 0.78rem;
  }
}
