.profile-objective {
  display: flex;
  /* background-color: antiquewhite; */
  padding: 10px 0;
  font-family: "Lato";
  height: 70px;
  width: 250px;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: all 0.2s;
}

.profile-objective:hover {
  background-color: rgba(148, 153, 195, 0.2);
  border-radius: 4px;
}

.profile-objective > .profile-objective-icon {
  /* background-color: #9499C3; */
  background-color: black;
  border-width: 1px;
  border-style: solid;
  height: 25px;
  width: 25px;
  border-radius: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  translate: 15px 0;
}

.profile-objective > .info {
  /* background-color: aqua; */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 14px;
  margin-left: 40px;
}

.profile-objective > .info > .title {
  color: #e4e7ff;
  font-size: 1rem;
}

@media only screen and (max-width: 1000px) {
  .profile-objective {
    margin: 0 10px;
  }
}
