.profile-infometers {
  background-color: #1a1c48;
  width: 130px;
  height: 20vh;
  max-height: 120px;
  display: grid;
  grid-template-rows: 40% 55%;
  margin: 10px 10px;
  border-radius: 5px;
  transition: all 0.15s;
  cursor: pointer;
}

.profile-payment:hover {
  background-color: rgb(40, 190, 40);
}

.profile-qr:hover {
  background-color: #34b7f1;
}

.profile-merch:hover {
  background-color: rgb(249, 168, 18);
}

.profile-logout-info:hover {
  background-color: rgb(232, 32, 32);
}

.profile-infometers > .infometer-name {
  font-family: "Lato";
  font-size: 22px;
  font-weight: 400;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-infometers > .infometer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-infometers > .infometer-icon > i {
  font-size: 45px;
  color: white;
  text-align: center;
}

@media only screen and (max-width: 1300px) {
  .profile-infometers {
    width: 110px;
    height: 100px;
    margin: 0 15px;
  }

  .profile-infometers > .infometer-name {
    font-size: 13px;
  }

  .profile-infometers > .infometer-icon > i {
    font-size: 40px;
  }
}

@media only screen and (max-width: 1260px) {
  .profile-infometers {
    width: 100px;
    height: 90px;
    margin: 0 15px;
  }

  .profile-infometers > .infometer-name {
    font-size: 10px;
  }
}

@media only screen and (max-width: 1100px) {
  .profile-infometers {
    width: 90px;
    height: 85px;
    margin: 0 10px;
  }
}

@media only screen and (max-width: 1000px) {
  .profile-infometers {
    background-color: #0d0e23;
  }
}

@media screen and (max-width: 400px) {
  .profile-infometers {
    width: 100px;
    height: 90px;
    margin: 0 30px;
  }
}
