.guest-page-container {
  margin-top: 2rem;
  background-color: transparent;
  color: white;
  border-radius: rem;
  text-align: center;
}

.guest-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.guest-profile-div {
  height: 20%;
  width: 90%;
  margin: 0 5%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-family: monospace;
}

.guest-name {
  font-size: 2rem;
  color: #f9c74f;
}

.guest-profile-div img {
  width: 25%;
  border-radius: 20%;
}

.guest-details {
  height: 100%;
  width: 50%;
  font-size: large;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.guest-designation {
  width: 80%;
  text-align: center;
}

.lecture-timeline {
  display: flex;
  /*flex-direction: column;*/
  gap: 2rem;
}

.lecture-venue a {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
}

.lecture-venue a:hover {
  color: #f9c74f;
}

.timeline-icon {
  text-align: left;
}

.lecture-venue,
.gl-contact-details {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.gl-contact-details {
  font-size: 1.3rem;
}

.guest-intro {
  padding: 2rem 15%;
  padding-top: 0.5rem;
  text-align: justify;
  font-size: medium;
}

.guest-intro p {
  letter-spacing: 1px;
  word-spacing: 5px;
  line-height: 1.2;
  font-size: 1.1rem;
  font-family: "Lato";
}

.lecture-des {
  display: flex;
  width: 100%;
  padding: 1rem 10%;
  flex-direction: column;
  gap: 1rem;
}

.lecture-des p {
  letter-spacing: 1px;
  word-spacing: 5px;
  line-height: 1.2;
  font-size: 1.1rem;
  text-align: justify;
  font-family: "Lato";
}

.lecture-des h1 {
  color: #2baae2;
  font-family: Neue Einstellung, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.gl-contact {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0;
}

.gl-contact h1 {
  color: #2baae2;
  font-family: Neue Einstellung, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.gl-contact-details a {
  text-decoration: none;
  color: white;
}

.gl-contact-details a:hover {
  color: #f9c74f;
}

.gl-register button {
  background-color: #f9c74f;
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  font-size: large;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.gl-register button:hover {
  background-color: #f9c74f;
  transform: scale(1.05);
}

.registered {
  color: green;
}

.registered i {
  margin-right: 1rem;
}

/* Media Queries */

@media screen and (max-width: 992px) {
  .guest-profile-div img {
    width: 40%;
    margin-bottom: 1rem;
  }

  .lecture-timeline {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .guest-profile-div {
    height: auto;
    flex-direction: column;
  }

  .guest-profile-div img {
    width: 50%;
    margin-bottom: 1rem;
  }

  .lecture-timeline {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media screen and (max-width: 576px) {
  .guest-profile-div img {
    width: 70%;
  }

  .guest-details {
    width: 100%;
  }

  .lecture-des {
    padding: 1rem 5%;
  }

  .gl-contact {
    margin-bottom: 1rem;
  }
}
