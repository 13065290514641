.gl {
  height: 100%;
  width: 100%;
  justify-content: center;
}

.gl-heading {
  display: flex;
  color: whitesmoke;
  justify-content: center;
  font-size: 2.65rem;
  width: 95%;
  font-family: "Titillium Web", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  margin: 1rem 2.5%;
  text-align: center;
  padding: 2% 1%;
  font-weight: 600;
}

.gldiv1,
.gldiv2,
.gldiv3 {
  color: cadetblue;
  display: flex;
  height: 30%;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.gl-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
  row-gap: max(3vw, 25px);
  width: 90%;
  margin: 0 5%;
  margin-bottom: 3rem;
}

.gl-card {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;
  width: 45%;
  aspect-ratio: 2.3/1;
  overflow: hidden;
  isolation: isolate;
  background: #cfcfcf;
  color: rgb(55, 55, 55);
  box-sizing: border-box;
  transition-duration: 0.75s;
  font-family: "Rubik", sans-serif;
  font-size: 0.9rem;
  box-shadow: 0 0 10px rgba(220, 220, 220, 0.45),
    0 0 20px rgba(197, 197, 197, 0.25), 0 0 40px rgba(128, 128, 128, 0.25);
}

.gl-card:hover {
  border-radius: 15px;
  box-shadow: 0 0 20px rgba(220, 220, 220, 0.6),
    0 0 30px rgba(197, 197, 197, 0.3), 0 0 40px rgba(128, 128, 128, 0.3);
}

.gl-card-top {
  z-index: -1;
  width: 100%;
  height: 0%;
  top: 0;
  background: linear-gradient(
    to right,
    rgb(78, 101, 215) 0%,
    rgb(78, 101, 215) 25%,
    #ff72dc 25%,
    #ff72dc 100%
  );
  background: #a452aa;
}

.gl-card-bottom {
  z-index: -1;
  width: 100%;
  height: 16%;
  bottom: 0;
  background-color: #0d5394;
  padding-left: 6.5%;
  display: flex;
  align-items: center;
  position: relative;
}

.gl-card-bottom > a {
  position: absolute;
  left: 40%;
  text-decoration: none;
  color: white;
  transition-duration: 0.2s;
}

.gl-card-bottom > a > i {
  font-size: 0.9em;
  margin-left: 0;
  margin-right: 3px;
  transition-duration: 0.2s;
}

.gl-card-bottom > a:hover {
  scale: 1.02;
  color: #b3fffc;
}

.gl-card-bottom > a:hover > i {
  margin-left: 3px;
  margin-right: 0;
}

.gl-card-bottom span {
  color: #b3fffc;
}

.gl-card-bottom > button {
  background-color: #051a2f;
  border: none;
  font-size: 1.05em;
  color: white;
  cursor: pointer;
  padding: 0.35em 0.9em;
  /* margin-right: 27.5%; */
  transition-duration: 0.2s;
  border-radius: 5px;
  /*border: solid 1px white;*/
}

.gl-card-bottom > button:hover {
  scale: 1.04;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.gl-card img {
  position: absolute;
  height: 175%;
  width: auto;
  top: 0;
  left: 100%;
  transform: translate(-56%, -32%);
  border-radius: 50%;
  border: 3px solid #cfcfcf;
  transition-duration: 0.75s;
  /* filter: brightness(0.9) saturate(0.7); */
}

.gl-card:hover img {
  transform: translate(-59%, -32%);
}

.gl-card-content {
  width: 53%;
  padding-left: 2.5%;
}

.gl-card-about {
  font-size: 0.9em;
}

.gl-card-about > p {
  margin-top: 0.5em;
  color: rgb(0, 0, 0);
}

.gl-card-name {
  font-size: 1.375em;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.gl-card-name > div > p:first-child {
  color: #0d5394;
  font-weight: 600;
}

.gl-card-name > div > p:last-child {
  font-size: 0.85em;
}

.gl-card-name > i {
  font-size: 1.9em;
  margin-right: 15px;
}

.gl-card-line {
  content: "";
  display: block;
  width: 90%;
  height: 2px;
  background-color: rgb(112, 112, 112);
  margin-top: 1.2em;
  margin-bottom: 0.8em;
  margin-left: -5px;
}

@media (max-width: 1450px) {
  .gl-card {
    font-size: 0.8rem;
  }
}

@media (max-width: 1350px) {
  .gl-container {
    column-gap: 3vw;
  }

  .gl-card {
    width: 48%;
  }
}

@media (max-width: 1220px) {
  .gl-card {
    font-size: 0.75rem;
  }
}

@media (max-width: 1120px) {
  .gl-card {
    width: 48.3%;
    font-size: 0.68rem;
  }
}

@media (max-width: 1000px) {
  .gl-card {
    width: 55%;
    font-size: 0.7rem;
  }
}

@media (max-width: 900px) {
  .gl-heading {
    font-size: 2.55rem;
  }

  .gl-card {
    width: 67%;
  }
}

@media (max-width: 750px) {
  .gl-card {
    width: 75%;
    aspect-ratio: 2.1/1;
    font-size: 0.69rem;
  }

  .gl-card-content {
    width: 51%;
  }

  .gl-card img {
    transform: translate(-55%, -32%);
  }

  .gl-card:hover img {
    transform: translate(-57%, -32%);
  }
}

@media (max-width: 650px) {
  .gl-heading {
    font-size: 2.45rem;
  }

  .gl-card {
    width: 80%;
    font-size: 0.65rem;
  }
}

@media (max-width: 570px) {
  .gl-heading {
    font-size: 2.4rem;
  }

  .gl-card {
    width: 90%;
    font-size: 0.62rem;
  }
}

@media (max-width: 470px) {
  .gl-heading {
    font-size: 2.3rem;
  }

  .gl-card {
    width: 100%;
  }
}

@media (max-width: 420px) {
  .gl-heading {
    font-size: 2.2rem;
  }

  .gl-card {
    font-size: 0.53rem;
  }
}

@media (max-width: 370px) {
  .gl-heading {
    font-size: 2.1rem;
  }

  .gl-card {
    font-size: 0.45rem;
  }
}

@media (max-width: 310px) {
  .gl-card {
    font-size: 0.38rem;
  }
}
