@import url("https://fonts.googleapis.com/css2?family=Merriweather&display=swap");

.events-page {
  width: 100%;
  margin-bottom: 4rem;
  min-height: 80vh;
  /* border: solid 1px black; */
  color: white;
  /* background-color: #212121; */
  font-family: Neue Einstellung, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
}

.events-header {
  /* text-align: center; */
  /* height: calc(100vh - 84px - (0.245 * 0.3 * 85.5vw) - 0.5em); */
  width: 53%;
  margin-left: 11%;
  margin-right: 37%;
  margin-top: 4rem;
  margin-bottom: 2rem;
  position: relative;
  /* isolation: isolate; */
  font-size: 1rem;
}

.events-header > h1 {
  font-size: 3em;
  margin-bottom: 0.42em;
  color: #7bcaef;
}
.events-header > h1 > span {
  font-size: 1.5em;
  color: white;
}
.events-header > p {
  font-size: 1.4em;
  margin-bottom: 1em;
}

.events-header > img {
  position: absolute;
  top: 50%;
  right: -30%;
  animation: astro-animation 1.8s linear 0.3s infinite alternate-reverse;
}
.events-header > img,
img.fixed-astronaut {
  width: 200px;
  animation-play-state: running;
}
img.fixed-astronaut {
  position: fixed;
  right: 0;
  animation: astro-animation2 1.8s linear 1.01s infinite alternate-reverse;
  z-index: 2;
  transition-duration: 1.5s;
  filter: drop-shadow(9px 9px 10px rgba(63, 63, 63, 0.98));
}

@keyframes astro-animation {
  0% {
    scale: 0.98;
    transform: translate(50%, calc(-50% - 20px));
  }
  100% {
    scale: 1.02;
    transform: translate(50%, -50%);
  }
}

@keyframes astro-animation2 {
  0% {
    scale: 0.98;
    transform: translate(-10px, calc(-2 - 20px));
  }
  100% {
    scale: 1.02;
    transform: translate(-10px, -2%);
  }
}

.events-holder {
  width: 95%;
  margin: 1rem 2.5%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.event-card {
  width: calc(0.3 * 90vw);
  height: calc(0.3 * 90vw);
  margin: 0.5em 0;
  position: relative;
  isolation: isolate;
  overflow: visible;
  /* border: solid 2px black; */
  /* font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif; */
}
.event-card h2 {
  width: 100%;
  /* font-family: "Merriweather", "Titillium Web", "Franklin Gothic",
    "Arial Narrow", Arial, sans-serif; */
}
.event-card-inner {
  width: 51%;
  height: 51%;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -51%);
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: rebeccapurple; */
  /* color: white; */
  transition-duration: 0.3s;
  transition-delay: 0.2s;
  transition-timing-function: ease-out;
  overflow: visible;
}
.event-card:hover > .event-card-inner {
  transition-delay: 0s;
  transition-timing-function: ease-in;
  height: 90%;
  width: 90%;
}

.event-card-inner {
  background-image: url("https://encrypted-tbn3.google.com/images?q=tbn:ANd9GcTQqoB4xBnIkmUg5pxDyYW2h0Q1pRTsbQBOfhg-E-y4iLFicsyi");
  /* width: 70px;
  height: 70px; */
  /* a black shadow from left and white from right */
  box-shadow: inset 30px 0 35px 6px rgba(0, 0, 0, 0.9),
    inset -16px 0 40px 2px rgba(255, 255, 255, 0.16),
    -8px 0 30px 3px rgba(255, 255, 255, 0.13);
  /* background-size: 250%; */
  /* background-size: 21%; */
  background-size: cover;
  border-radius: 50%;
  /* animation-name: rotate;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  animation-timing-function: linear; */
}
@keyframes rotate {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 250%;
  }
}
/* .event-card-inner:hover {
  animation-duration: 14s;
  background-color: #00000098;
} */

.event-card-inner > div {
  width: calc(0.8 * 0.3 * 90vw);
  text-align: center;
  transform: translateY(-0.85em);
  transition-duration: 0.5s;
  transition-delay: 0s;
  z-index: 2;
  font-size: 105%;
  overflow: hidden;
}

.event-card-inner > div > h2 > span {
  margin-bottom: 0.1em;
  font-weight: 800;
}
.event-card-inner > div > h2 > span.event-uid {
  font-size: 0.63em;
  font-weight: 700;
}

.event-card-inner > div > p {
  font-size: 0.9em;
  font-weight: 500;
}

.event-card-inner > div > h2,
.event-card-inner > div > p {
  overflow: hidden;
  width: 100%;
}
.event-card-inner > div > h2 > span,
.event-card-inner > div > p > span {
  display: block;
  width: 100%;
}
.event-card-inner > div > h2 > span,
.event-card-inner > div > p > span,
.event-card-inner > div > a {
  transform: translateY(100%);
  opacity: 0;
  transition-duration: 0.5s;
  transition-delay: 0;
}
.event-card-inner > div > h2 > span {
  transition-duration: 0.2s;
}
.event-card-inner > div > h2 > span {
  transform: translateY(0%);
}
.event-card:hover > .event-card-inner > div > h2 > span,
.event-card:hover > .event-card-inner > div > p > span,
.event-card:hover > .event-card-inner > div > a {
  transform: translateY(0);
  opacity: 1;
}
.event-card:hover > .event-card-inner > div > h2 > span,
.event-card:hover > .event-card-inner > div > p > span,
.event-card:hover > .event-card-inner > div > a {
  transition-delay: 0.25s;
}
.event-card:hover > .event-card-inner > div > h2 > span {
  animation: fade-in 0.25s linear 0.2s 1;
  transition-delay: 0.2s;
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.4;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-out {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.5;
  }
  50% {
    opacity: 0.25;
  }
  100% {
    opacity: 0;
  }
}
/* .event-card-inner:hover > div {
  transition-delay: 0.1s;
  scale: 1;
} */

/* .event-card-inner > div > h2,
.event-card-inner > div > p {
  color: white;
} */

.event-card img {
  height: 50%;
  width: auto;
  position: absolute;
  filter: drop-shadow(9px 9px 10px rgba(8, 8, 8, 0.98));
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-duration: 0.2s;
  transition-delay: 0.15s;
  transition-timing-function: ease-in;
  z-index: 4;
}
.event-card:hover > .event-card-inner img {
  top: 98%;
  height: 37.5%;
}

/* .event-card a {
  padding: 0.3em 0.5em;
  margin-top: 1.2em;
  font-size: 0.9em;
  cursor: pointer;
  position: relative;
} */

.event-card-inner .event-title {
  width: 95%;
  margin: 0 2.5%;
  margin-bottom: 0.8em;
  font-size: 1.7em;
}

.event-card > .event-title {
  text-align: center;
  position: relative;
  margin-top: 84%;
  height: 20%;
  overflow: hidden;
}
.event-card > .event-title > h2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition-delay: 0.25s;
  transition-duration: 0.4s;
  width: 100%;
  font-size: 1.8em;
}
.event-card > .event-title > h2 > span {
  width: 100%;
  display: block;
  margin-bottom: 0.5em;
  font-weight: 700;
  font-size: 1em;
}
.event-card > .event-title > h2 > span.events-uid {
  font-weight: 400;
  font-size: 0.6em;
}

.event-card:hover .event-title > h2 {
  transition-delay: 0.1s;
  top: -50%;
  opacity: 0.5;
}

.event-card a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  overflow: hidden;
  text-decoration: none;
  height: 2.5rem;
  margin: 0 22.5%;
  padding: 0.2em 0.5em;
  margin-top: 1.2em;
  background-size: 300% 300%;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
  transition: 0.5s;
  animation: gradient_301 5s ease infinite;
  border: double 4px transparent;
  background-image: linear-gradient(#212121, #212121),
    linear-gradient(
      137.48deg,
      #ffdb3b 10%,
      #fe53bb 35%,
      #8f51ea 57%,
      #46d1ff 77%,
      rgb(106, 255, 106) 90%
    );
  background-origin: border-box;
  background-clip: content-box, border-box;
  cursor: pointer;
  font-size: 1em;
}

.event-card a > .container-stars {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(1rem);
  border-radius: 5rem;
}

.event-card a > strong {
  z-index: 2;
  font-family: monospace;
  font-size: 1.4em;
  letter-spacing: 1.5px;
  color: #ffffff;
  text-shadow: 0 0 1px white;
}

.event-card a > .glow {
  position: absolute;
  display: flex;
  width: 92%;
}

.event-card a > .glow > .circle {
  width: 100%;
  height: 30px;
  filter: blur(2rem);
  animation: pulse_3011 4s infinite;
  z-index: -1;
}

.event-card a > .glow > .circle:nth-of-type(1) {
  background: rgba(254, 83, 186, 0.636);
}

.event-card a > .glow > .circle:nth-of-type(2) {
  background: rgba(142, 81, 234, 0.704);
}

.event-card a > .container-stars {
  background-color: rgba(0, 0, 0, 0.45);
}

.event-card a:hover > .container-stars {
  z-index: 1;
  background-color: transparent;
}

.event-card a:hover {
  transform: scale(1.1);
}

.event-card a:active {
  border: double 4px #fe53bb;
  background-origin: border-box;
  background-clip: content-box, border-box;
  animation: none;
}

.event-card a:active .circle {
  background: #fe53bb;
}

.event-card a > .container-stars > div {
  position: relative;
  background: transparent;
  width: 200rem;
  height: 200rem;
}

.event-card a > .container-stars > div::after {
  content: "";
  position: absolute;
  top: -10rem;
  left: -100rem;
  width: 100%;
  height: 100%;
  animation: animStarRotate 90s linear infinite;
}

.event-card a > .container-stars > div::after {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
}

.event-card a > .container-stars > div::before {
  content: "";
  position: absolute;
  top: 0;
  left: -50%;
  width: 170%;
  height: 500%;
  animation: animStar 60s linear infinite;
}

.event-card a > .container-stars > div::before {
  background-image: radial-gradient(#ffffff 1px, transparent 1%);
  background-size: 50px 50px;
  opacity: 0.5;
}

@keyframes animStar {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-135rem);
  }
}

@keyframes animStarRotate {
  from {
    transform: rotate(360deg);
  }

  to {
    transform: rotate(0);
  }
}

@keyframes gradient_301 {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes pulse_3011 {
  0% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.75);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

@media (max-width: 1440px) {
  .event-card {
    font-size: 0.9rem;
  }
}

@media (max-width: 1250px) {
  .events-header {
    width: 60%;
    margin-right: 29%;
  }
  .events-header > img {
    right: -22%;
  }
  .events-header > img,
  img.fixed-astronaut {
    width: 175px;
  }

  .event-card {
    width: calc(0.35 * 90vw);
    height: calc(0.35 * 90vw);
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.35 * 90vw);
  }
  .event-card a {
    width: 60%;
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media (max-width: 1100px) {
  .events-header {
    width: 66%;
    margin-right: 23%;
    font-size: 0.9rem;
  }
  .events-header > img {
    right: -15%;
  }

  .event-card {
    font-size: 0.75rem;
  }
}

@media (max-width: 1010px) {
  .events-holder {
    justify-content: space-around;
  }

  .event-card {
    margin-bottom: 2.5em;
  }
  .event-card-inner {
    width: 90%;
    height: 90%;
  }
  .event-card img {
    top: 95%;
    height: 32%;
  }
  .event-card:hover > .event-card-inner img {
    top: 95%;
    height: 32%;
  }
  .event-card > .event-title > h2 {
    top: -50%;
    opacity: 0%;
  }
  .event-card-inner > div > h2 > span,
  .event-card-inner > div > p > span,
  .event-card-inner > div > a {
    transform: translateY(0);
    opacity: 1;
  }
  .event-card:hover > .event-card-inner > div > h2 > span {
    animation: none;
  }
}

@media (max-width: 950px) {
  .events-header {
    font-size: 0.85rem;
  }
  .events-header > h1 {
    font-size: 2.95em;
  }
  .events-header > img {
    width: 150px;
  }

  .event-card {
    width: calc(0.4 * 90vw);
    height: calc(0.4 * 90vw);
    margin-bottom: 2.35em;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.4 * 90vw);
  }
}

@media (max-width: 850px) {
  .events-header {
    font-size: 0.8rem;
    width: 78%;
    margin-right: 11%;
  }
  .events-header > h1 {
    text-align: center;
  }
  .events-header > img {
    display: none;
    animation-play-state: paused;
  }
  img.fixed-astronaut {
    display: none;
    animation-play-state: paused;
  }

  .event-card {
    width: calc(0.45 * 90vw);
    height: calc(0.45 * 90vw);
    font-size: 0.72rem;
    margin-bottom: 2.2em;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.45 * 90vw);
  }
}

@media (max-width: 720px) {
  img.fixed-astronaut {
    width: 150px;
  }

  .event-card {
    width: calc(0.5 * 90vw);
    height: calc(0.5 * 90vw);
    font-size: 0.7rem;
    margin-bottom: 1.5em;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.5 * 90vw);
  }
}

@media (max-width: 650px) {
  .events-header {
    font-size: 0.75rem;
  }

  .event-card {
    font-size: 0.65rem;
  }
}

@media (max-width: 625px) {
  /* .events-header {
    font-size: 3rem;
  } */
  .events-header {
    text-align: center;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .events-header > h1 > span {
    display: block;
  }

  .event-card {
    width: calc(0.65 * 90vw);
    height: calc(0.65 * 90vw);
    font-size: 0.8rem;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.65 * 90vw);
    scale: 1;
    opacity: 1;
  }
}

@media (max-width: 550px) {
  /* .events-header {
    font-size: 3.4rem;
  } */

  .event-card {
    width: calc(0.65 * 90vw);
    height: calc(0.65 * 90vw);
    font-size: 0.7rem;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.65 * 90vw);
  }
}

@media (max-width: 475px) {
  .event-card {
    width: calc(0.75 * 90vw);
    height: calc(0.75 * 90vw);
    font-size: 0.67rem;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.75 * 90vw);
  }
}

@media (max-width: 400px) {
  .events-header {
    font-size: 0.67rem;
  }

  .event-card {
    width: calc(0.85 * 90vw);
    height: calc(0.85 * 90vw);
    font-size: 0.65rem;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.85 * 90vw);
  }
}

@media (max-width: 350px) {
  .event-card {
    width: calc(0.95 * 90vw);
    height: calc(0.95 * 90vw);
    font-size: 0.64rem;
  }
  .event-card-inner > div {
    width: calc(0.75 * 0.95 * 90vw);
  }
}

@media (max-width: 300px) {
  /* .events-header {
    font-size: 3rem;
  } */

  .event-card {
    font-size: 0.58rem;
  }
}
