.contact {
  /* background-color: black; */
  width: 100%;
  min-height: calc(100vh - 83.5px);
  /* min-width: 100vw; */
  color: white;
  font-family: "Rubik", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-wrapper {
  display: flex;
  justify-content: center;
  width: 80%;
  margin: 10px;
  background-color: rgba(47, 47, 47, 0.881);
  border-radius: 50px;
}

.contact-form-div {
  height: 100%;
  width: 60%;
  padding: 0rem 0;
}

#contact-form {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 3vh;
}

#contact-form h1 {
  font-size: 2rem;
  font-weight: 900;
  color: white;
  margin: 20px;
}

.contact-header {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 2vh;
  background: linear-gradient(rgba(0, 0.3, 0.4, 0.7), rgba(79, 4, 71, 0.7)),
    url("../../public/assets/img/contact/header-bg.jpg") no-repeat center center/cover;
  border-radius: 50px 0 0 50px;
  border-color: aliceblue;
  padding: 0rem 0.5rem;
}

.contact-input-box {
  width: 80%;
  font-size: large;
  font-weight: 900;
}

.contact-input-box input,
textarea {
  width: 100%;
  outline: none;
  background-color: transparent;
  color: white;
  padding: 5px;
  margin: 10px;
  border: none;
  border-bottom: 0.1px solid rgb(195 192 192);
  font-size: 1.1rem;
  font-weight: 550;
  word-spacing: 2px;
  letter-spacing: 1.2px;
}

.contact-input-box textarea {
  border: 0.1px solid rgb(195 192 192);
  border-radius: 5px;
  resize: none;
}

.contact-input-box input::placeholder,
textarea::placeholder {
  color: rgba(199, 199, 199, 0.682);
  font-size: 1rem;
  font-weight: 400;
}

.contact-input-box input:focus {
  border-bottom: 1px solid rgb(208, 230, 235);
}

.contact-input-box textarea:focus {
  border: 1px solid rgb(204, 190, 190);
}

.contact-input-box input[type="submit"] {
  /* background-color: rgb(130 56 191); */
  background-image: linear-gradient(to right,
      #580872,
      #62406c,
      #79397f,
      #64277b);
  box-shadow: 0 4px 15px 0 rgba(31, 35, 39, 0.75);
  color: white;
  border: none;
  padding: 10px;
  font-size: 1.5rem;
  width: 50%;
  font-weight: 800;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.contact-input-box input[type="submit"]:hover {
  background-color: rgb(218 215 215);
  color: rgb(250, 243, 249);
  /* background-image: linear-gradient(to right, #25aae1, #d790d7, #f0c0e6, #4e5f57); */
  box-shadow: 0 4px 15px 0 rgba(151, 255, 252, 0.75);
}

.contact-input-box input[type="submit"]:active {
  background-color: rgb(130 56 191);
  color: white;
}

.contact-heading h2 {
  font-size: 2.2rem;
  font-weight: 900;
  margin: 30px 5px;
}

.contact-heading p {
  font-size: 1.2rem;
  font-weight: 500;
  margin: 10px 5px;
  letter-spacing: 1.5;
}

.contact-info-content {
  font-size: 1.4rem;
  margin-right: 10px;
}

.contact-info-content a {
  text-decoration: none;
  color: white;
  transition: all 0.3s ease-in-out;
}

.contact-info-content a:hover {
  color: rgb(222 72 203);
}

.contact-social-links a {
  text-decoration: none;
  color: white;
  font-size: 1.4rem;
  margin: 1.2rem;

  transition: all 0.3s ease-in-out;
}

.contact-social-links a:hover {
  color: rgb(222 72 203);
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 5vh;
  padding: 1rem;
}

.contact-social-links {
  margin-top: 2rem;
}

.contact-link-wrap {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 1.1rem;
}

.contact-link-icon {
  font-size: 1.45rem;
}

/*Responsive for contact page*/

@media screen and (max-width: 924px) {
  .contact {
    padding: 3rem 0;
  }

  .contact-wrapper {
    flex-direction: column;
    width: 85%;
  }

  .contact-header {
    width: 100%;
    border-radius: 50px 50px 0 0;
  }

  .contact-form-div {
    width: 100%;
  }

  .contact-input-box input[type="submit"] {
    margin-left: 0;
    width: 100%;
  }

  .contact-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }

  .contact-social-links {
    margin-top: 0;
    margin: 1rem;
  }
}

@media screen and (max-width: 768px) {
  .contact-wrapper {
    flex-direction: column;
    width: 85%;
  }

  .contact-header {
    width: 100%;
    border-radius: 50px 50px 0 0;
  }

  .contact-form-div {
    width: 100%;
  }

  .contact-input-box input[type="submit"] {
    margin-left: 0;
    width: 100%;
  }

  .contact-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }

  .contact-social-links {
    margin-top: 0;
  }

  #contact-form h1 {
    font-size: 1.5rem;
  }
}

@media screen and (max-width: 425px) {
  .contact-wrapper {
    flex-direction: column;
    width: 100%;
  }

  .contact-header {
    width: 100%;
    border-radius: 50px 50px 0 0;
  }

  .contact-form-div {
    width: 100%;
  }

  .contact-input-box input[type="submit"] {
    margin-left: 0;
    width: 100%;
  }

  .contact-info-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2vh;
  }

  .contact-social-links {
    margin-top: 0;
  }

  #contact-form h1 {
    font-size: 1.2rem;
  }
}