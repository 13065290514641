@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");

.header-nssc {
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  /* background-color: black; */
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  padding: 0.3rem 1%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 5;
}

.header-nssc img {
  scale: 1.7;
  padding-left: 5px;
}

.nav-item {
  position: relative;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 25px;
  letter-spacing: -1px;
  text-decoration: none;
  margin-left: 1em;
  transition: all 0.5s ease;
  font-family: monospace;
}

.nav-item:hover {
  color: rgb(206, 105, 21);
}

.icons-navbar {
  position: absolute;
  right: 5%;
  font-size: 2.3rem;
  color: #fff;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

/* @media (max-width: 992px) {
    .header-nssc {
        padding: 1.3rem 5%;
    }
} */

@media (max-width: 1000px) {
  .icons-navbar {
    display: inline-flex;
  }

  #check:checked ~ .icons-navbar #menu-icon {
    display: none;
  }

  .icons-navbar #close-icon {
    display: none;
  }

  #check:checked ~ .icons-navbar #close-icon {
    display: block;
  }

  .navbar {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(50px);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: all 0.3s ease;
  }

  #check:checked ~ .navbar {
    height: auto;
  }

  .nav-item {
    display: block;
    font-size: 1.1rem;
    margin: 1.5rem 0;
    text-align: center;
    transform: translateY(-50px);
    opacity: 0;
    transition: all 0.3s ease;
  }

  #check:checked ~ .navbar a {
    transform: translateY(0);
    opacity: 1;
    transition-delay: calc(0.15s * var(--i));
  }

  .header-nssc {
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
    background-color: black;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.3rem 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;
  }

  .header-nssc img {
    scale: 1.65;
    padding-left: 5px;
  }
}

@media only screen and (max-width: 1262px) and (min-width: 1110px) {
  .nav-item {
    position: relative;
    color: #fff;
    font-size: 0.9rem;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.13px;
    text-decoration: none;
    margin-left: 1rem;
    transition: all 0.5s ease;
  }
}

@media only screen and (max-width: 1110px) and (min-width: 1000px) {
  .nav-item {
    position: relative;
    color: #fff;
    font-size: 0.8rem;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: -0.13px;
    text-decoration: none;
    margin-left: 0.8rem;
    transition: all 0.5s ease;
  }
}

/* @media only screen and (max-width: 1312px) and (min-width: 1178px) {
    .nav-item {
        position: relative;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.13px;
        text-decoration: none;
        margin-left: 2.5rem;
        transition: all 0.5s ease;
    }
}

@media only screen and (max-width: 1178px) and (min-width: 1084px) {
    .nav-item {
        position: relative;
        color: #fff;
        font-size: 0.7rem;
        font-weight: 500;
        line-height: 25px;
        letter-spacing: -0.13px;
        text-decoration: none;
        margin-left: 2.5rem;
        transition: all 0.5s ease;
    }
}

@media only screen and (max-width: 1084px) and (min-width: 1000px) {
    .nav-item {
        position: relative;
        color: #fff;
        font-size: 0.67rem;
        font-weight: 600;
        line-height: 25px;
        letter-spacing: -0.13px;
        text-decoration: none;
        margin-left: 2rem;
        transition: all 0.5s ease;


    }

    .header-nssc img {
        scale: 1.5;
    }
} */
