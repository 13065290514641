.member-card {
  display: grid;
  background-color: white;
  border-radius: 20px;
  position: relative;
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-align: center;
  overflow: hidden;
  border-left: white solid 3.33%;
  box-sizing: border-box;
}

.member-card-gov-gsec {
  grid-template-rows: 64.51% 35.49%;
  /* aspect-ratio: 1/1.55; */
}

.member-card-team {
  /* aspect-ratio: 1/1.35; */
  grid-template-rows: 74.07% 25.93%;
}

.member-card-img {
  display: grid;
  position: relative;
  width: 100%;
  /* aspect-ratio: 1/1; */
  height: 100%;
  border-radius: 20px 20px 0 0;
  background-size: cover;
}

.member-card-img img {
  /* aspect-ratio: 1/1; */
  object-fit: cover;
  align-self: center;
  justify-self: center;
  position: absolute;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition-duration: 1s;
}

/* css for member picture */
.member-card-img img:nth-child(1) {
  width: 55%;
  height: 55%;
  border-radius: 50%;
}

/* css for animating images */
.member-card-img img:nth-child(2) {
  z-index: 1;
  width: 65%;
  height: 65%;
  animation: member-card-rotate-animation 14s infinite linear;
}

.member-card-img img:nth-child(3) {
  z-index: 1;
  width: 63%;
  height: 63%;
  animation: member-card-rotate-animation 20s infinite linear;
}

@keyframes member-card-rotate-animation {
  100% {
    transform: rotate(-360deg);
  }
}

.member-card:hover .member-card-img img {
  scale: 1.1;
  margin-left: 1em;
}

.member-card-info {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-self: center;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 0.5em;
  box-sizing: border-box;
}

.member-card-name {
  margin-bottom: 0.75vw;
  margin-top: 0.75vw;
  color: black;
  font-size: 1.1em;
  word-wrap: normal;
}

.member-card-line {
  height: 1.5px;
  width: 80%;
  background-color: black;
}

.member-card-data {
  width: 100%;
  margin-bottom: 0.75vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.member-card-post {
  font-size: 1em;
  font-weight: 600;
  color: black;
}

.member-card-white-box {
  position: absolute;
  height: 50%;
  width: 3.32%;
  left: 0;
  top: calc(28% - 3em);
  background-color: white;
  z-index: 3;
}

.member-card-gov-gsec>.member-card-socials {
  position: absolute;
  top: calc(30% - 3em);
  left: 3.33%;
}

.member-card-team>.member-card-socials {
  position: absolute;
  top: calc(35% - 3em);
  left: 3.33%;
}

.member-card-socials li {
  position: relative;
  list-style: none;
  color: white;
  background: rgba(2, 0, 49, 0.6);
  width: 2em;
  height: 2em;
  border-top-right-radius: 1.5em;
  border-bottom-right-radius: 1.5em;
  border: solid white 1px;
  border-left: none;
  text-align: center;
  line-height: 2em;
  transform-origin: left;
  transition-duration: 1s;
  transform: translateX(-2.3em);
  z-index: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0.2em;
}

.member-card-socials li:nth-child(3) {
  padding-bottom: 0.1em;
  box-sizing: border-box;
}

/* hover transition for social link */
.member-card:hover .member-card-socials li {
  transform: translateX(-0.15em);
}

.member-card-social-icon {
  color: white;
}

.member-card-social-icon:hover {
  cursor: pointer;
  color: #03e9f4;
}

/* responsive */
@media screen and (min-width: 1801px) {
  .member-card-team {
    width: 12vw;
    height: calc(1.35 * 12vw);
    font-size: 1rem;
  }

  .member-card-gov-gsec {
    width: 13vw;
    height: calc(1.55 * 13vw);
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1800px) {
  .member-card-team {
    width: 16vw;
    height: calc(1.35 * 16vw);
    font-size: 1rem;
  }

  .member-card-gov-gsec {
    width: 16.5vw;
    height: calc(1.55 * 16.5vw);
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1300px) {
  .member-card-team {
    width: 18vw;
    height: calc(1.35 * 18vw);
    font-size: 1rem;
  }

  .member-card-gov-gsec {
    width: 18.5vw;
    height: calc(1.55 * 18.5vw);
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1150px) {
  .member-card-team {
    width: 20vw;
    height: calc(1.35 * 20vw);
    font-size: 1rem;
  }

  .member-card-gov-gsec {
    width: 20.5vw;
    height: calc(1.55 * 20.5vw);
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 1030px) {

  .member-card-team,
  .member-card-gov-gsec {
    width: 22.5vw;
    font-size: 0.9rem;
  }

  .member-card-team {
    height: calc(1.35 * 22.5vw);
  }

  .member-card-gov-gsec {
    height: calc(1.55 * 22.5vw);
  }
}

@media screen and (max-width: 850px) {

  .member-card-team,
  .member-card-gov-gsec {
    width: 25vw;
    font-size: 0.85rem;
  }

  .member-card-team {
    height: calc(1.35 * 25vw);
  }

  .member-card-gov-gsec {
    height: calc(1.55 * 25vw);
  }

  .member-card-socials li {
    transform: translateX(-0.15em);
  }

  .member-card .member-card-img img {
    margin-left: 1em;
    margin-top: 0.5em;
  }

  .member-card:hover .member-card-img img {
    scale: 1;
  }
}

@media screen and (max-width: 700px) {

  .member-card-team,
  .member-card-gov-gsec {
    width: 40vw;
    font-size: 1.05rem;
  }

  .member-card-team {
    height: calc(1.35 * 40vw);
  }

  .member-card-gov-gsec {
    height: calc(1.55 * 40vw);
  }
}

@media screen and (max-width: 550px) {

  .member-card-team,
  .member-card-gov-gsec {
    width: 63vw;
    font-size: 1.16rem;
  }

  .member-card-team {
    height: calc(1.35 * 63vw);
  }

  .member-card-gov-gsec {
    height: calc(1.55 * 63vw);
  }
}

@media screen and (max-width: 400px) {

  .member-card-team,
  .member-card-gov-gsec {
    width: 75vw;
    font-size: 1.1rem;
  }

  .member-card-team {
    height: calc(1.35 * 75vw);
  }

  .member-card-gov-gsec {
    height: calc(1.55 * 75vw);
  }
}