@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body::-webkit-scrollbar {
  width: 0.5rem;
}

body::-webkit-scrollbar-track {
  background: #1e1e24;
}

body::-webkit-scrollbar-thumb {
  background: rgb(28, 39, 85);
  border-radius: 10px;
}

body {
  background-color: black;
}

.particle-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.stars,
.twinkling,
.clouds {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  isolation: isolate;
  z-index: -1;
}

.stars {
  z-index: 0;
  background: #000 url("/public/assets/img/background/starry_bg.jpg") repeat top center;
  background-image: url("/public/assets/img/background/starry_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.twinkling {
  z-index: 1;
  background: transparent url("/public/assets/img/background/twinkle.png") repeat top center;
  animation: move-twink-back 200s linear infinite;
}

.clouds {
  z-index: 2;
  background: transparent url("/public/assets/img/background/cloud.png") repeat top center;
  animation: move-clouds-back 200s linear infinite;
}

@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

@keyframes move-clouds-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: 10000px 0;
  }
}

@media screen and (max-width: 767px) {
  #ts-iframe {
    width: 100%;
  }
}