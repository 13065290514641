@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;700;800;900&display=swap');

.merchandise-item{
    display: grid;
    /* grid-template-rows: 85% 15%; */
    width: 400px;
    height: 500px;
    padding: 20px 0;
    border-bottom-width: 1px;
    border-bottom-color: white;
    border-bottom-style: solid;
    background: linear-gradient(
        180deg,
        rgba(255, 255, 255, 0.1),
        rgba(255, 255, 255, 0)
    );
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 20px;
    border: 1px solid rgba(255, 255, 255, 0.18);
    box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
    font-size: 1rem;
    perspective: 2500px;
    animation: all 0.5s;
    position: relative;
}

/* .merchandise-item:hover{
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
} */

.wrapper {
    transition: all 0.5s;
    position: absolute;
    width: 100%;
    z-index: -1;
  }
  
  .card:hover .wrapper {
    transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
    box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  }
  
  .wrapper::before,
  .wrapper::after {
    content: "";
    opacity: 0;
    width: 100%;
    height: 80px;
    transition: all 0.5s;
    position: absolute;
    left: 0;
  }
  .wrapper::before {
    top: 0;
    height: 100%;
    background-image: linear-gradient(
      to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  .wrapper::after {
    bottom: 0;
    opacity: 1;
    background-image: linear-gradient(
      to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%
    );
  }
  
  .card:hover .wrapper::before,
  .wrapper::after {
    opacity: 1;
  }
  
  .card:hover .wrapper::after {
    height: 120px;
  }

.character {
    width: 100%;
    opacity: 0;
    transition: all 0.5s;
    position: absolute;
    z-index: -1;
}
  
.merchandise-item:hover .character {
    opacity: 1;
    transform: translate3d(0%, -30%, 100px);
}

.merch-item-img-wrap{
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: center;
}

.merch-item-img-bg{
    height: 250px;
    width: 250px;
    border-radius: 100%;
    background-color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

.merch-item-img-bg > img{
    width: 420px;
}

.merch-item-info{
    background-color: rgb(23, 135, 255);
    border-radius: 30px;
    font-family: 'Raleway';
    display: grid;
    grid-template-rows: 50% 50%;
    width: 90%;
    margin: 0 auto;
}

.merch-item-title{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    font-weight: 700;
    color: white;
}

.merch-item-buy{
    display: grid;
    align-items: center;
    grid-template-columns: 30% 40% 30%;
    /* background-color: violet; */
}

.merch-item-price{
    font-size: 18px;
    font-weight: 500;
    /* background-color: aqua; */
    align-items: center;
    justify-content: center;
    display: flex;
}

.merch-item-options{
    /* background-color: green; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.merch-item-btn{
    font-size: 23px;
    /* background-color: red; */
    align-items: center;
    justify-content: center;
    display: flex;
}

@media (max-width: 1300px){
    .merchandise-item{
        width: 300px;
        height: 400px;
    }

    .merch-item-img-bg > img{
        width: 10px;
    }

    .merch-item-img-bg{
        height: 200px;
        width: 200px;
    }

    .merch-item-title{
        font-size: 23px;
    }

    .merch-item-price{
        font-size: 16px;
    }

    .merch-item-options{
        font-size: 16px;
    }

    .merch-item-btn{
        font-size: 20px;
    }   

}

@media (max-width: 1050px){
    .merchandise-item{
        margin: 20px 0;
    }
    
}

@media (max-width: 425px){
    .merchandise-item{
        width: 250px;
        height: 350px;
    }

    .merch-item-img-bg > img{
        width: 200px;
        height: 200px;
    }

    .merch-item-img-bg{
        height: 200px;
        width: 200px;
    }

    .merch-item-title{
        font-size: 23px;
    }

    .merch-item-price{
        font-size: 16px;
    }

    .merch-item-options{
        font-size: 16px;
    }

    .merch-item-btn{
        font-size: 20px;
    }   
}