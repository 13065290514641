@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600&family=Roboto:wght@100;300;400&family=Space+Mono:wght@400;700&display=swap");

.eventdetails {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 83.5px);
  overflow: hidden;
  width: 100%;
  /* background-color: rgb(10, 17, 48); */
  color: white;
  font-family: monospace;
  font-family: lato;
}

.eventdetails-intro-contact {
  display: flex;
  flex-direction: column;
  height: 90%;
  width: 30%;
  text-align: justify;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
}

.eventdetails-central-section {
  height: 90%;
  display: flex;
  width: 30%;
  flex-direction: column;
  justify-content: flex-start;
}

.eventdetails-rules {
  display: flex;
  height: 100%;
  text-align: justify;
  width: 30%;
  flex-direction: column;
  justify-content: flex-start;
}

.eventdetails-intro-heading,
.eventdetails-ps-heading,
.eventdetails-contact-heading,
.eventdetails-rules-heading,
.eventdetails-team-heading,
.eventdetails-prizepool-heading {
  font-size: 1.5rem;
  font-weight: 600;
  margin: 0.4rem 0;
  text-align: center;
  color: blueviolet;
}

.eventdetails-heading {
  font-size: 2rem;
  font-weight: 600;
  margin-top: 1rem;
  text-align: center;
}

.eventdetails-image {
  padding: 1rem;
  height: 8rem;
  object-fit: contain;
  animation: imganim 3s ease infinite alternate;
}

/* @keyframes imganim {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(10px);
  }
} */

.eventdetails-intro-para,
.eventdetails-ps-para {
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.4px;
}

.eventdetails-rules-list {
  font-size: 0.8rem;
  font-weight: 400;
  letter-spacing: 0.4px;
  height: 75%;
  overflow: auto;
  padding: 0 1rem;
}

/*Scrollbar Styling*/

.eventdetails-rules-list::-webkit-scrollbar,
.eventdetails-central-section::-webkit-scrollbar {
  width: 0.2rem;
}

.eventdetails-rules-list::-webkit-scrollbar-track,
.eventdetails-central-section::-webkit-scrollbar-track {
  background-color: transparent;
}

.eventdetails-rules-list::-webkit-scrollbar-thumb,
.eventdetails-central-section::-webkit-scrollbar-thumb {
  background-color: rgb(28, 39, 85);
  border-radius: 10px;
}

.eventdetails-rules-list-item {
  margin: 10px 0;
}

.eventdetails-main {
  position: relative;
  display: flex;
  height: 100%;
  width: 95%;
  flex-direction: row;
  justify-content: space-between;
  margin: auto;
}

.eventdetails-ps {
  text-align: center;
}

.eventdetails-ps-btn {
  margin-top: 0.5rem;
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: rgb(28, 39, 85);
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.eventdetails-ps-btn:hover {
  background-color: rgb(48, 64, 137);
  border: 2px solid white;
}

.eventdetails-contact {
  text-align: center;
}

.eventdetails-contact-details {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 0.7rem;
  font-family: math;
  gap: 1rem;
  margin-top: 0.5rem;
  text-align: start;
}

.headset-icon {
  font-size: 1.3rem;
  margin-right: 0.5rem;
}

.eventdetails-register {
  margin-top: 1rem;
  text-align: center;
}

.eventdetails-register-btn,
.eventdetails-unregister-btn {
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: rgb(28, 39, 85);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  margin-top: 1rem;
}

.ed-invisible {
  display: none;
}

.eventdetails-register-btn {
  background-color: green;
}

.eventdetails-register-btn:hover {
  background-color: rgb(5, 141, 5);
  border: 2px solid white;
}

.eventdetails-unregister-btn:hover {
  background-color: red;
  border: 2px solid white;
}

.eventdetails-registration-status-registered {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5%;
  margin: 0.5rem 0;
  color: green;
  font-size: 0.7rem;
}

.eventdetails-registration-status-unregistered {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2%;
  margin: 0.5rem 0;
  font-size: 0.7rem;
  color: red;
}

.eventdetails-register-icon {
  font-size: 1.5rem;
}

.eventdetails-register-btn:disabled {
  background-color: rgb(38, 47, 83);
  border: none;
  cursor: not-allowed;
}

.eventdetails-contact-details-item {
  text-align: center;
}

.submission-ps input {
  outline: none;
  border: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  padding: 0.5rem;
  margin-bottom: 1rem;
  border-radius: 20px;
}

.submission-ps {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0;
}

.ps-submit-btn {
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: rgb(28, 39, 85);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.eventdetails-main.mobile-view {
  display: none;
}

.eventdetails-create-team {
  margin: 1rem 0;
  text-align: center;
}

.eventdetails-create-team form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.eventdetails-create-team form input[type="text"] {
  outline: none;
  border: none;
  border: 1px solid white;
  background-color: transparent;
  color: white;
  font-size: 0.7rem;
  font-weight: 400;
  padding: 0.5rem;
  width: 60%;
  border-radius: 20px;
}

.eventdetails-create-team form input[type="text"]:focus {
  border: 1px solid rgb(155, 155, 155);
}

.eventdetails-create-team form input[type="submit"] {
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  border-radius: 10px;
  background-color: rgb(28, 39, 85);
  color: white;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
}

.eventdetails-create-team form input[type="submit"]:hover {
  background-color: rgb(48, 64, 137);
  border: 2px solid white;
}

.eventdetails-team {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  padding: 1rem 0;
}

.eventdetails-team-details-item-heading {
  font-size: 1.3rem;
  font-weight: 600;
  color: rgb(255, 255, 255);
  text-align: center;
  margin-bottom: 0.5rem;
}

.eventdetails-team-details-item-subheading {
  font-size: 1rem;
  font-weight: 600;
  color: rgb(43, 226, 171);
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
  margin-top: 0.5rem;
  text-align: center;
}

.ed-participant-input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.ed-plus-btn {
  border: none;
  font-size: 1.5rem;
  color: rgb(1, 255, 18);
  cursor: pointer;
  background-color: transparent;
  position: absolute;
  right: 50%;
  bottom: 0;
}

.ed-minus-btn {
  border: none;
  font-size: 1.8rem;
  color: rgb(255, 0, 0);
  cursor: pointer;
  padding: 0.1rem;
  border-radius: 50%;
  background-color: transparent;
  position: absolute;
  right: 15%;
  bottom: 0;
}

.eventdetails-ps-btn:disabled {
  background-color: rgb(38, 47, 83);
  border: none;
  cursor: not-allowed;
}

.eventdetails-elink {
  text-align: center;
}

.eventdetails-elink a {
  display: block;
  margin-bottom: 0.7rem;
  text-decoration: none;
  outline: none;
  border: none;
  background-color: green;
  color: white;
  padding: 0.7rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.eventdetails-elink a:hover {
  background-color: rgb(5, 141, 5);
  outline: 1px solid white;
}

.ed-contact-prize {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  padding: 1rem 0;
}

.eventdetails-prizepool-details {
  text-align: center;
  font-size: 1.5rem;
  font-weight: 600;
}

/* Responsiveness for big screens*/

@media only screen and (min-width: 1300px) {
  .eventdetails-intro-heading,
  .eventdetails-ps-heading,
  .eventdetails-contact-heading,
  .eventdetails-rules-heading {
    font-size: 2rem;
  }

  .eventdetails-heading {
    font-size: 3rem;
  }

  .eventdetails-intro-para,
  .eventdetails-ps-para {
    font-size: 1rem;
  }

  .eventdetails-rules-list {
    font-size: 1rem;
  }

  .eventdetails-register-btn {
    font-size: 1.2rem;
  }

  .eventdetails-register-checkbox-label {
    font-size: 1.2rem;
  }

  .eventdetails-contact-details {
    font-size: 1rem;
  }

  .eventdetails-register {
    font-size: 1.2rem;
  }

  .eventdetails-rules-list {
    height: 73%;
  }

  /* 
  .eventdetails-central-section {
    gap: 1rem;
  } */
}

/*Responsiveness for small screens*/

@media only screen and (max-width: 1000px) {
  .eventdetails {
    height: auto;
    padding-bottom: 5vh;
    align-items: center;
    justify-content: center;
  }

  .eventdetails-main {
    display: none;
  }

  .eventdetails-main.mobile-view {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    gap: 2rem;
  }

  .eventdetails-intro-contact {
    width: 100%;
    padding: 0 5%;
    gap: 0;
  }

  .eventdetails-central-section {
    width: 100%;
  }

  .eventdetails-rules {
    width: 100%;
    padding: 0 5%;
  }

  .eventdetails-intro-heading,
  .eventdetails-ps-heading,
  .eventdetails-contact-heading,
  .eventdetails-rules-heading,
  .eventdetails-team-heading {
    font-size: 1.8rem;
    margin: 1rem 0;
  }

  .eventdetails-heading {
    font-size: 2.3rem;
  }

  .eventdetails-intro-para,
  .eventdetails-ps-para {
    font-size: 1rem;
  }

  .eventdetails-rules-list {
    font-size: 1rem;
  }

  .eventdetails-register-btn {
    font-size: 1rem;
  }

  .eventdetails-register-checkbox-label {
    font-size: 1rem;
  }

  .eventdetails-contact-details {
    font-size: 1rem;
  }

  .eventdetails-register {
    font-size: 1rem;
  }

  .eventdetails-contact {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .eventdetails-image {
    margin: 1rem;
  }
}
