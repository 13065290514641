.profile-quick-info {
  display: grid;
  grid-template-columns: 44% 66%;
  /* background-color: brown; */
  width: 250px;
  height: 50px;
  font-family: "Lato";
}

.profile-quick-info > .status-bar {
  /* background-color: darkgreen; */
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  font-weight: 300;
  font-size: 16px;
  padding-left: 10px;
  z-index: 1;
}

.profile-quick-info > .status-bar > .loading-bar {
  width: 180px;
  height: 5px;
  background-color: rgba(148, 153, 195, 0.2);
  border-radius: 10px;
  margin-top: 4px;
  z-index: 2;
}

.profile-quick-info > .status-bar > .loading-bar > .loading-bar-fill {
  width: 80px;
  height: 5px;
  background-color: #298bfe;
  border-radius: 10px;
  margin-top: 4px;
  margin: 0;
  z-index: 2;
}

.profile-quick-info > .status-bar-info {
  /* background-color: orange; */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  font-size: 15 px;
  font-weight: 500;
  text-align: center;
  text-align: start;
}

@media only screen and (max-width: 1300px) {
  .profile-quick-info > .status-bar > .loading-bar {
    width: 180px;
  }

  .profile-quick-info > .status-bar > .loading-bar > .loading-bar-fill {
    width: 80px;
    height: 5px;
    background-color: #298bfe;
    border-radius: 10px;
    margin-top: 4px;
    margin: 0;
  }
}

@media only screen and (max-width: 1260px) {
  .profile-quick-info > .status-bar > .loading-bar {
    width: 160px;
  }
}

@media only screen and (max-width: 1260px) {
  .profile-quick-info > .status-bar > .loading-bar {
    width: 170px;
  }
}
