@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Orbitron:wght@500&family=Poppins:wght@400;600&family=Syncopate:wght@700&display=swap");

:root {
  --merch-card-height: 50vh;
  --merch-card-width: calc(var(--merch-card-height) / 1);
}

/* * {
  box-sizing: border-box;
} */
.merch-page {
  box-sizing: border-box;
  width: 100vw;
  height: 87vh;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10vw;
  position: relative;
  overflow-x: hidden;
}

.merch-note {
  position: absolute;
  /* background-color: #04A7F3; */
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: white;
  font-family: Lato;
  font-size: 1em;
  animation: scroll 40s linear infinite;
  white-space: nowrap;
  transform: translateY(-50%);
}

@keyframes scroll {
  0% {
    transform: translateX(100%);
  }

  100% {
    transform: translateX(-100%);
  }
}

.merch-note>span {
  padding: 0 10px;
  background-color: rgb(247, 67, 67);
  word-spacing: 2px;

}

.merch-page-title {
  font-family: "Orbitron", sans-serif;
  color: white;
  font-size: 40px;
  text-align: center;
  padding-top: 40px;
  padding-bottom: 60px;
}

.small-text-merch {
  font-size: 20px;
  color: red;
}

.merch-card-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  display: grid;
  grid-template-columns: auto auto;
  column-gap: 15vw;
}

.merch-card {
  box-sizing: border-box;
  border-radius: 2px;
  width: var(--merch-card-width);
  height: var(--merch-card-height);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  /* padding: 0 36px; */
  perspective: 2500px;
}

.hover-me {
  position: absolute;
  width: 6.6vw;
  left: -6.6vw;
  top: -5vw;
  transform: scaleX(1);
}

.click-me {
  position: absolute;
  width: 6.6vw;
  left: -6.6vw;
  top: -5vw;
  transform: scaleX(1);
  opacity: 0;
}

.cover-image {
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.15s;
}

.merch-card:hover .cover-image {
  opacity: 0;
  /* height: 0%;
  width: 0%; */
}

/* .cover-image1 {
  border-radius: 2px;
  box-sizing: border-box;
  width: 0%;
  height: 0%;
  object-fit: cover;
  transition: opacity 0.15s;
  opacity: 0;
}

.merch-card:hover .cover-image1 {
  opacity: 0.5;
  height: 100%;
  width: 100%;
} */

.merch-wrapper {
  box-sizing: border-box;
  border-radius: 2px;
  transition: all 0.5s;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.merch-card:hover .merch-wrapper {
  transform: perspective(900px) translateY(-5%) rotateX(25deg) translateZ(0);
  box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 2px 35px 32px -8px rgba(0, 0, 0, 0.75);
  background-color: white;
  background-image: url("../../public/assets/img/merchandise/space-doodle.png");
  background-size: 100%;
  opacity: 0.8;
}

.merch-wrapper::after {
  /* content: ""; */
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}

.merch-wrapper::before {
  content: "";
  opacity: 0;
  width: 100%;
  height: 80px;
  transition: all 0.5s;
  position: absolute;
  left: 0;
}

.merch-wrapper::before {
  top: 0;
  height: 100%;
  background-image: linear-gradient(to top,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%);
}

.merch-wrapper::after {
  bottom: 0;
  opacity: 1;
  background-image: linear-gradient(to bottom,
      transparent 46%,
      rgba(12, 13, 19, 0.5) 68%,
      rgba(12, 13, 19) 97%);
}

.merch-card:hover .merch-wrapper::before,
.merch-wrapper::after {
  opacity: 1;
}

.merch-card:hover .merch-wrapper::after {
  height: 120px;
}

.merch-title {
  box-sizing: border-box;
  width: 100%;
  transition: transform 0.5s;
}

.merch-card:hover .merch-title {
  transform: translate3d(0%, -50px, 100px);
}

.merch-character {
  box-sizing: border-box;
  width: 120%;
  opacity: 0;
  transition: all 0.5s;
  position: absolute;
  z-index: -1;
}

.merch-card:hover .merch-character {
  opacity: 1;
  transform: translate3d(0%, -30%, 100px);
}

.merch-price {
  bottom: -60px;
  font-size: 35px;
  position: absolute;
  color: #e1dbe9;
  font-style: italic;
  text-align: center;
  font-family: "Bebas Neue", cursive;
  letter-spacing: 2px;
  padding: 5px 30px;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
  clip-path: polygon(0 0, 100% 0, 100% 70%, 90% 100%, 0 100%);
  background-color: #04A7F3;
  font-weight: 200;
}

@media (max-width: 950px) {
  .merch-card-wrapper {
    grid-template-columns: auto;
  }

  .merch-page-title {
    font-size: 50px;
  }

  :root {
    --merch-card-height: 50vw;
  }

  .merch-card-2 {
    display: none;
  }

  .click-me {
    width: 10vw;
    left: -10vw;
    top: -8vw;
  }

  .hover-me {
    width: 10vw;
    left: -10vw;
    top: -8vw;
  }
}

/* @media (max-height: 800px) {
  .merch-page-title {
    font-size: 50px;
    padding-top: 40px;
    padding-bottom: 50px;
  }
}

@media (max-height: 650px) {
  .merch-page-title {
    font-size: 50px;
    padding-top: 40px;
    padding-bottom: 50px;
  }
} */

@media (max-width: 800px) {
  .click-me {
    opacity: 1;
  }

  .hover-me {
    opacity: 0;
  }

  .merch-note>span {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .merch-page-title {
    font-size: 35px;
    padding-top: 40px;
    padding-bottom: 50px;
  }

  .merch-note {
    animation-duration: 20s;
  }

  .merch-note>span {
    font-size: 12px;
  }

  .merch-price {
    font-size: 20px;
    bottom: -40px;
    padding: 3x 15px;
  }
}