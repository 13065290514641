@import "~react-image-gallery/styles/css/image-gallery.css";
.gallery {
  margin-left: 10vw;
  margin-right: 10vw;
  border-radius: 20px;
  padding-bottom: 5vh;
}

.gallery-images {
  background-color: rgba(0, 0, 0, 0.75);
}

.gallery-Heading {
  padding: 2% 1%;
  margin: 1rem 0;
  text-align: center;
  font-size: 27px;
  font-family: "Titillium Web", "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  color: #fff;
}
.gallery-filterItem ul {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;
  padding: 0;
  gap: 20px;
  margin-bottom: 30px;
}

.gallery-filterButton {
  background: #0a0a0a;
  color: #fff;
  padding: 10px 24px;
  text-transform: uppercase;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  font-size: 16px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
}
.gallery-filterButton.active {
  box-shadow: 1px 1px 5px rgb(27, 108, 159), -1px -1px 5px rgb(27, 108, 159),
    1px -1px 5px rgb(27, 108, 159), 0px 0px 10px #fff;
}

.gallery-filterButton:hover {
  background: #252525;
}

@media only screen and (min-width: 768px) {
  .image-gallery-image {
    width: 50% !important;
  }
  .image-gallery-image.fullscreen {
    width: 100% !important;
  }
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 60px;
  width: 46px;
}
@media only screen and (max-width: 992px) {
  .gallery-filterItem ul {
    flex-wrap: wrap;
    justify-content: center;
  }
  .gallery-filterButton {
    padding: 10px 16px;
    font-size: 14px;
  }
}

@media only screen and (max-width: 768px) {
  .gallery-filterItem ul {
    /* flex-direction: column; */
    align-items: center;
  }
  .gallery-filterButton {
    width: 100%;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .gallery-filterButton {
    padding: 10px 12px;
    font-size: 12px;
  }
}
