/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Orbitron&family=Rubik+Iso&family=Space+Grotesk:wght@300&display=swap"); */

.about_page {
  height: 100%;
  width: 100%;
  overflow: hidden;
  font-family: "Rubik", sans-serif;
}

.about_inner_box {
  /* background-color: black; */
  width: 100%;
  height: 100%;
  position: relative;
}

.about_title {
  color: rgb(246, 236, 222);
  font-size: 1.6rem;
  /* font-family: Krona One; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5%;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
}

@media screen and (max-width: 350px) {
  .about_title {
    font-size: 1.7rem;
  }
}

.about_cardtitle {
  /* padding-bottom: 2rem; */
  /* color: antiquewhite; */
  font-size: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5%;
  padding-top: 2rem;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
}

.about_earth {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10%;
  z-index: 3;
}

.about_earth_img {
  filter: drop-shadow(0.3rem 0.1rem 0.4rem#909091b8);
}

.about_moon {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 10%;
  z-index: 3;
}

.about_moon_img {
  width: 12.5rem;
  margin-bottom: 1.5rem;
  filter: drop-shadow(0.3rem 0.1rem 0.4rem#ffffffb8);
}

.about_page1 {
  display: flex;
  color: antiquewhite;
  justify-content: center;
  position: relative;
  width: 100%;
  height: 60%;
  font-size: 1.4rem;
}

.about_box1 {
  margin-top: 2rem;
  width: 50%;
  text-align: right;
  padding-right: 6rem;
}

.about_box2 {
  margin-top: 4.5rem;
  padding-left: 6rem;
  width: 50%;
}

.about_event1,
.about_event2,
.about_event3,
.about_event4,
.about_event5,
.about_event6 {
  margin-bottom: 5rem;
}

.about-line {
  width: 0.2rem;
  height: calc(100% + 1rem);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: linear-gradient(
    rgba(255, 255, 255, 0.2),
    white,
    rgba(255, 255, 255, 0.2)
  );
  z-index: 1;
}

.about_table {
  padding-top: 2rem;
  /* font-family: Krona One; */
  /* background-color: black; */
  height: 100%;
  width: 100%;
}

.about_title_line1 {
  text-align: center;
  /* color: rgb(246, 242, 238); */
  /* font-size: 3rem; */
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5%;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
}

.about_link {
  text-decoration: none;
}

.about_title_line2 {
  margin-top: 2.5%;
  color: rgb(246, 242, 238);
  font-size: 1.35rem;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 5%;
  padding: 0 10rem;
  text-align: justify;
}

.abouttable {
  text-align: center;
  padding-inline-start: 0.5rem;
  padding: 0.5rem;
  color: rgb(246, 242, 238);
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  padding-bottom: 4rem;
  font-size: 1.2rem;
}

.abouttable table {
  border-collapse: collapse;
}

.abouttable td {
  padding: 0.5rem;
  border: 0.3rem solid #0000;
  background: linear-gradient(#131219, #131219) padding-box,
    linear-gradient(var(--angle), #070707, rgba(255, 255, 255, 0.4)) border-box;
  animation: 4s rotate linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.abouttable th {
  padding: 0.2rem;
  border: 0.5rem solid #0000;
  background: linear-gradient(#131219, #131219) padding-box,
    linear-gradient(var(--angle), #070707, rgba(104, 122, 255, 0.777))
      border-box;
  animation: 4s rotate linear infinite;
  font-size: 1.4rem;
}

@media screen and (max-width: 390px) {
  .abouttable th {
    font-size: 1rem;
  }
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

.about_planet {
  width: 100%;
  /* height: 100%; */
  padding-top: 5%;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: black; */
  min-height: min(728px, calc(100vh - 84px));
  padding-bottom: 5%;
}

.about_head {
  text-align: center;
  color: #f2eded;
  /* font-family: Arial, sans-serif; */
  display: block;
  padding-left: min(60px, 10%);
  padding-right: min(50px, 10%);
}

.about_button1,
.about_button2 {
  border-radius: 4.1rem;
  background: rgba(217, 217, 217, 0.3);
  width: 10rem;
  height: 2.2rem;
  padding: 0.3rem;
  color: #fff;
  /* font-family: Jockey One; */
  font-size: 1.2rem;
  font-weight: 400;
  border: 2px solid rgb(236, 131, 119);
  margin-bottom: 20px;
  text-decoration: none;
  transition: all 0.3s ease-in;
}

.about_button1:hover {
  background-color: #616262;
}

.about_button2:hover {
  background-color: #565757;
}

.about_button {
  padding: 50px 0px;
  display: flex;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  text-decoration: none;
}

.about_main {
  font-size: 4rem;
  padding-bottom: 2rem;
  /* font-family: Krona One; */
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
  text-align: left;
}

.about_sub {
  /* font-family: Jockey One; */
  font-size: 1.6rem;
  padding-bottom: 2rem;
  text-align: left;
}

.about_rest {
  font-size: 18px;
}

.about_img {
  filter: drop-shadow(3px 1px 40px rgba(255, 255, 255, 0.25));
  margin-left: auto;
  width: 30rem;
}

.about_phototitle {
  /* background-color: black; */
  /* color: white; */
  /* font-family: Krona One; */
  /* font-size: 3rem; */
  text-align: center;
  padding-bottom: 60px;
  font-family: "Titillium Web", "Franklin Gothic", "Arial Narrow", Arial,
    sans-serif;
}

.about_paragraph {
  /* background-color: black; */
  width: 100%;
  height: 100%;
  position: relative;
}

.about_wrapper {
  display: flex;
  /* grid-gap: 5px; */
  gap: 4rem;
  -webkit-flex-wrap: nowrap;
  -webkit-justify-content: center;
  /* background-color: black; */
  /* margin: auto; */
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  /* background-color: black; */
}

.about_at-accordion__item {
  position: relative;
  height: 372px;
  border-radius: 1rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50%;
  -webkit-flex-basis: calc((100% - 20px) / 6);
  flex-basis: calc((100% - 20px) / 6);
  transition: all 0.8s cubic-bezier(0.23, 1, 0.32, 1);
  overflow: hidden;
  position: relative;
  display: flex;
  width: calc(33% - 2.1rem);
  min-width: 250px;
  max-width: 200px;
}

:root {
  --dark: grey;
  --light: #ffffff36;
  --grad1: linear-gradient(to top, #363736 0%, #555656 80%, #818181 100%);
  --grad2: linear-gradient(60deg, #a9a8a5 20%, #797f80 40%, #444244 100%);
}

.about_upperline {
  font-weight: 900;
  color: white;
  font-size: 1.4rem;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
  width: 100%;
  text-align: center;
  letter-spacing: 1px;
}

.about_lowerline {
  font-size: 1rem;
  font-weight: 200;
  color: white;
  text-align: left;
  padding: 0 1rem;
}

.about_wholecard {
  width: 80vw;
  margin: auto;
  align-content: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  /* background-color: black; */
}

.about_card {
  position: relative;
  display: flex;
  width: calc(33% - 2.1rem);
  min-width: 250px;
  max-width: 200px;
  /* width: calc(33% - 4rem); */
  border: 1px solid var(--light);
  height: 310px;
  margin: 1rem;
  overflow: hidden;
  border-radius: 10px;
  backdrop-filter: blur(6px);
  box-shadow: 0px 0px 4px #ffd2194d;
  bottom: 0;
  transition: all 200ms;
}

.about_card-content {
  margin: 1rem 0 0 0;
  position: relative;
  /* padding: 1.5rem; */
  z-index: 2;
  width: 100%;
}

.about_card-content svg {
  height: 100px;
  fill: white;
}

.about_card-background-1,
.about_card-background-2 {
  opacity: 0.65;
  position: absolute;
  height: 100%;
  width: 100%;
  transition: all 200ms;
}

.about_card-background-1 {
  background: var(--grad1);
  background-size: 550px 550px;
  background-position: center;
}

.about_card-background-2 {
  background: var(--grad2);
  background-size: 550px 550px;
  mix-blend-mode: color-burn;
  background-position: top right;
}

.about_card:hover .about_card-background-2 {
  background-position: bottom left;
  transition: all 200ms;
}

/* Responsiveness*/
@media screen and (max-width: 1010px) {
  .about_title_line2 {
    padding: 25px 25px;
  }
}

@media screen and (max-width: 1000px) {
  .about_planet {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .about_img {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 768px) {
  .about_img {
    align-items: center;
  }

  .about_page1 {
    padding: 25px 25px;
    font-size: 1rem;
  }

  .about_main {
    text-align: center;
    font-size: 3.4rem;
  }

  .about_sub {
    font-size: 1.2rem;
    text-align: center;
  }

  .about_title {
    font-size: 1.4rem;
  }

  .about_box1 {
    padding-right: 2rem;
  }

  .about_box2 {
    padding-left: 2rem;
  }

  .about_title_line2 {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 600px) {
  .about_title_line2 {
    text-align: left;
  }
}

@media screen and (max-width: 500px) {
  .about_img img {
    width: 100%;
    height: auto;
  }

  .about_main {
    font-size: 3rem;
  }

  .about_sub {
    font-size: 1.1rem;
  }

  .about_title_line2 {
    font-size: 0.95rem;
  }
}

@media screen and (max-width: 420px) {
  .about_title {
    text-align: center;
    font-size: 1.25rem;
  }

  .about_main {
    font-size: 2.8rem;
  }

  .about_sub {
    font-size: 1rem;
  }

  .about_title_line2 {
    font-size: 0.88rem;
  }
}

@media screen and (max-width: 350px) {
  .about_title_line2 {
    font-size: 0.82rem;
  }
}

.number {
  font-size: 4rem;
  color: rgb(143, 140, 140);
  text-align: center;
}
