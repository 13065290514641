@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400&family=Space+Mono:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600&family=Roboto:wght@100;300;400&family=Space+Mono:wght@400;700&display=swap");

@keyframes astronaut-breathing {
  0% {
    scale: 1;
  }

  10% {
    scale: 1;
  }

  40% {
    scale: 1.05;
  }

  60% {
    scale: 1.05;
  }

  90% {
    scale: 1;
  }

  100% {
    scale: 1;
  }
}

.profile-page {
  /* background-color: #1a1c48;
  background-image: url("../../public/assets/img/profile/profile-background.png");
  background-position: center; */
  height: calc(100vh - 83.5px);
  padding: 1px;
  position: relative;
  overflow: hidden;
  /* overflow-x: hidden; */
}

.profile-header {
  /* display: flex;
    justify-content: space-between;
    align-items: baseline; */
  display: grid;
  grid-template-columns: 25% 50% 25%;
  /* background-color: violet; */
  align-items: center;
  padding: 10px;
}

.profile-page-main-content h1 {
  font-family: "Lato";
  font-weight: 300;
  font-size: 24px;
  text-align: center;
  padding: 10px 0;
  color: #ffffff;
  display: inline-block;
  /* background-color: red; */
}

.profile-header>p {
  font-family: "Lato";
  font-weight: 300;
  text-align: end;
  color: #34b7f1;
  /* background-color: aqua; */
  margin-left: 5px;
}

.profile-page-main-content {
  display: grid;
  grid-template-columns: 75% 25%;
  height: 100%;
}

.profile-page-section1 {
  display: grid;
  grid-template-columns: 33% 67%;
  height: 100%;
}

.profile-page-main-content .quick-info-section {
  /* background-color: pink; */
  display: flex;
  flex-direction: column;
  /* height: 100%; */
}

.section1-title>h1 {
  /* background-color: red; */
  /* height: 100px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.section2-title>h1 {
  /* background-color: red; */
  /* height: 100px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-page-section2>h1 {
  /* height: 100px; */
  text-align: center;
  /* background-color: red; */
  vertical-align: middle;
}

.quick-info-wrap {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  /* height: 70vh; */
  height: calc(100vh - 180px);
  /* overflow-y: scroll;
  overflow-x: visible; */
  overflow: auto;
  overflow-x: hidden;
}

/* scrollbar */
.quick-info-wrap::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.quick-info-wrap::-webkit-scrollbar {
  width: 8px;
}

.quick-info-wrap::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

/* .quick-info-wrap:hover::-webkit-scrollbar-thumb {
  background-color: #555;
} */

.profile-logout-button-wrap {
  /* background-color: blueviolet; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
}

.profile-logout {
  outline: none;
  width: 200px;
  height: 50px;
  border: 2px solid #000;
  font-family: "Lato";
  font-weight: 500;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  background: #000;
  color: #fff;
  z-index: 1;
  text-align: center;
}

/* .profile-logout:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    background: #e0e5ec;
    transition: all 0.3s ease;
} */

.profile-logout:hover {
  color: #000;
}

.profile-shadow {
  display: none;
}

.profile-logout:hover:after {
  left: auto;
  right: 0;
  width: 100%;
}

.profile-logout:active {
  top: 2px;
}

.profile-page-main-content .middle-section {
  /* background-color: palegreen; */
  height: 100%;
}

.profile-page-main-content .middle-section>.infometers-container {
  /* background-color: brown; */
  /* height: 150px; */
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-page-main-content .middle-section>.profile-astronaut-img {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile-page-main-content .middle-section>.astronaut-id {
  /* background-color: darkorange; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-page-main-content .middle-section>.astronaut-id p {
  font-family: "Lato";
  font-weight: 300;
  text-align: end;
  color: #34b7f1;
  font-size: 25px;
  translate: 0 10px;
}

.profile-page-main-content .middle-section>.profile-astronaut-img>.profile-astronaut {
  height: 34vh;
  translate: 0 35px;
  animation-name: astronaut-breathing;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  z-index: 1;
}

.profile-page-main-content .middle-section>.profile-astronaut-img>.profile-shadow {
  height: 5vh;
  translate: 0 0px;
  opacity: 0.6;
}

.profile-page-main-content .profile-page-section2 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.profile-page-main-content .objective-section {
  /* background-color: pink; */
  padding-left: 60px;
  height: 70vh;
  /* height: calc(100vh - 135px); */
  overflow-x: hidden;
  overflow-y: scroll;
}

/* scrollbar */
.objective-section::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}

.objective-section::-webkit-scrollbar {
  width: 8px;
}

.objective-section::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}

.profile-payment-text {
  font-family: "Lato";
  font-weight: 800;
  font-size: 1.4rem;
  text-align: center;
  padding: 10px 0;
}

.latest-updates-modal-holder {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background: rgba(11, 11, 11, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

@keyframes latest-update-modal-appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.latest-updates-modal {
  /* background-c olor: rgba(214, 214, 214, 0.25); */
  border-radius: 2em;
  width: auto;
  height: fit-content;
}

.latest-updates-modal-inner {
  background-color: rgb(17, 17, 17);
  position: relative;
  border-radius: 1em;
  margin: 2em;
  font-family: "Rubik", sans-serif;
  box-sizing: border-box;
  animation: latest-update-modal-appear 0.5s linear 0.5;
  color: black;
  box-shadow: 0 0 15px rgb(49, 49, 49), 0 0 20px rgb(83, 83, 83);
}

.latest-updates-modal-button {
  position: absolute;
  background-color: rgb(17, 17, 17);
  height: 2em;
  width: 2em;
  /* aspect-ratio: 1/1; */
  border-radius: 50%;
  border-style: solid;
  border-color: rgb(3, 137, 155);
  top: -0.6rem;
  right: -0.6rem;
  text-align: center;
  line-height: 2em;
  font-size: 1.3rem;
  cursor: pointer;
  transition-duration: 0.25s;
  color: white;
  box-shadow: 0 0 5px rgb(41, 41, 41), 0 0 12px rgb(83, 83, 83);
}

.latest-updates-modal-button i {
  transition-duration: 0.25s;
}

.latest-updates-modal-button:hover {
  color: rgb(201, 63, 63);
}

.latest-updates-modal-button:hover i {
  scale: 1.2;
}

@media only screen and (max-width: 1300px) {
  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-astronaut {
    height: 32.5vh;
    translate: 0 80px;
  }

  .profile-shadow-inverted {
    display: inline;
    translate: 0px 10px;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-shadow {
    height: 80px;
    translate: 0 60px;
  }

  .profile-page-main-content .objective-section {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1170px) {
  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-astronaut {
    height: 32.5vh;
    translate: 0 85px;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-shadow {
    height: 80px;
    translate: 0 90px;
  }

  .profile-page-main-content .objective-section {
    padding-left: 10px;
  }

  .profile-logout {
    height: 40px;
    width: 180px;
  }
}

@media only screen and (max-width: 1100px) {
  .profile-page {
    height: auto;
  }

  .profile-page-main-content {
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .profile-page-section1 {
    display: grid;
    grid-template-columns: 42.85% 57.14%;
  }

  .profile-page-main-content .middle-section>.infometers-container {
    flex-wrap: wrap;
    /* height: 250px; */
    width: 85%;
    margin: 0 auto;
    row-gap: 15px;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-astronaut {
    height: 32.5vh;
    translate: 0 35px;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-shadow {
    height: 80px;
    translate: 0 10px;
  }

  .profile-page-main-content .objective-section {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 1000px) {
  .profile-page {
    /* background-color: #1a1c48; */
    height: auto;
    background-image: none;
  }

  .profile-header {
    justify-content: center;
    align-items: center;
    margin: 15px 40px;
  }

  .profile-header>.profile-logout {
    display: inline-block;
    margin: 0 20px;
  }

  .profile-page-main-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .profile-page-section1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 1200px;
  }

  .profile-page-main-content .quick-info-section {
    width: 80vw;
    margin-top: 10px;
    margin-bottom: 30px;
    height: 500px;
  }

  .profile-page-main-content .quick-info-section>.quick-info-wrap {
    width: 75vw;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .profile-page-main-content .middle-section>.infometers-container {
    flex-wrap: wrap;
    height: 200px;
    width: 100%;
    margin: 0 auto;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img {
    height: 500px;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-shadow-inverted {
    display: inline;
    /* height: 270px; */
    width: 50%;
    scale: 2;
    opacity: 0.4;
  }

  .profile-page-main-content .middle-section>.profile-astronaut-img>.profile-shadow {
    height: 80px;
    translate: 0 10px;
    opacity: 1;
  }

  .profile-logout-button-wrap {
    display: none;
  }

  .profile-page-main-content .objective-section {
    width: 90vw;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 300px;
  }

  .profile-page-main-content .middle-section>.astronaut-id {
    translate: 0 -20px;
  }

  .profile-page-main-content .middle-section>.astronaut-id p {
    font-size: 20px;
  }
}

@media only screen and (max-width: 870px) {
  .profile-page-main-content .quick-info-section {
    width: 85vw;
  }

  .profile-page-main-content .quick-info-section>.quick-info-wrap {
    width: 85vw;
  }
}

@media only screen and (max-width: 790px) {
  .profile-page-main-content .quick-info-section {
    width: 90vw;
  }

  .profile-page-main-content .quick-info-section>.quick-info-wrap {
    width: 90vw;
  }
}

@media only screen and (max-width: 1000px) {
  .profile-page-section1 {
    flex-direction: column-reverse;
    height: 1267px;
  }

  .profile-page-main-content .middle-section {
    /* background-color: palegreen; */
    height: 847px;
  }

  .profile-page-main-content .quick-info-section {
    width: 400px;
  }

  .profile-page-main-content .quick-info-section>.quick-info-wrap {
    width: 400px;
  }

  .profile-page-main-content .middle-section>.infometers-container {
    flex-wrap: wrap;
    height: 238px;
    width: 450px;
    margin: 0 auto;
    margin-top: 83px;
    padding-top: 50px;
  }
}

@media only screen and (max-width: 650px) {
  .profile-page-main-content h1 {
    font-size: 20px;
  }

  .profile-header>p {
    font-size: 13px;
  }

  .profile-payment-text {
    font-size: 1.2rem;
  }
}

@media only screen and (max-width: 363px) {
  .profile-header>p {
    text-align: start;
    font-size: 11px;
  }

  .profile-page-main-content .quick-info-section {
    width: 100vw;
  }

  .profile-page-main-content .quick-info-section>.quick-info-wrap {
    width: 100vw;
  }

  .profile-payment-text {
    font-size: 1rem;
  }
}