.payment-page {
  width: 95%;
  margin: 0 auto;
  height: calc(100vh - 83.5px - 3rem);
  box-sizing: border-box;
  padding: 2rem 0;
  padding-top: 0.5rem;
  color: white;
  font-family: Neue Einstellung, ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
    sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
    Noto Color Emoji;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.payment-closed {
  text-align: center;
  color: #efbb7b;
  margin-top: 1rem;
  font-family: Neue Einstellung, ui-sans-serif, system-ui, -apple-system,
  BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans,
  sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol,
  Noto Color Emoji;
  font-weight: 600;
  font-size: 1.2em;
}

.payment-left h2,
.payment-right h2 {
  font-size: 1.65em;
  font-weight: 600;
  margin: 0.7em 0;
  margin-top: 0;
  text-align: center;
  color: #7bcaef;
}

.payment-left ul,
.payment-right ul {
  margin-bottom: 1.5em;
}

.payment-left li,
.payment-right li {
  font-size: 1.05em;
  margin-bottom: 3px;
}

.payment-left li > a,
.payment-right li > a {
  color: #4ac6ff;
}

.payment-page > div {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.payment-left {
  border-left: 2px solid white;
}

.payment-left > div,
.payment-right > div {
  margin: 1em 3.5%;
  padding-left: 1.5em;
  /* overflow-wrap: break-word; */
  overflow-y: auto;
}

.payment-left > div > div,
.payment-right > div > div {
  margin-right: 1em;
}

.payment-right {
  border-right: 2px solid white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.payment-left > div::-webkit-scrollbar,
.payment-right > div::-webkit-scrollbar {
  width: 4px;
}

.payment-left > div::-webkit-scrollbar-thumb,
.payment-right > div::-webkit-scrollbar-thumb {
  background: #2d4a57a4;
  border-radius: 10px;
}

@media (max-width: 1100px) {
  .payment-left {
    font-size: 0.9rem;
  }
}

@media (max-width: 1000px) {
  .payment-page {
    flex-direction: column;
    height: auto;
  }

  .payment-page > div {
    width: 100%;
    height: auto;
    border: none;
  }

  .payment-left, .payment-right {
    border: none;
    padding: 0.6em 5%;
    overflow: visible;
  }

  .payment-left > div,
  .payment-right > div {
    margin: 0;
  }

  .payment-right {
    border: none;
  }
}

@media (max-width: 950px) {
  .payment-left, .payment-right {
    font-size: 0.85rem;
  }
}

@media (max-width: 850px) {
  .payment-left, .payment-right {
    font-size: 0.8rem;
  }
}

@media (max-width: 650px) {
  .payment-left, .payment-right {
    font-size: 0.75rem;
  }
}

@media (max-width: 400px) {
  .payment-left, .payment-right {
    font-size: 0.67rem;
  }
}

@media (max-width: 350px) {
  .payment-left, .payment-right {
    font-size: 0.6rem;
  }
}
